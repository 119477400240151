.first-sec.blog-details {
  padding-bottom: 335px;

  .bl {
    max-width: 456px;

    &::before {
      top: 6px;
      height: calc(100% - 11px);
    }
  }
}

.blog-detail__content {
  img {
    margin-top: 9px;
    margin-bottom: 28px;
    border-radius: 28px;
    display: block;
    max-width: 100%;
  }

  .btn {
    margin-top: 10px;
  }
}

.news-sec.blog-details-page {
  margin-top: 0;
  .news-sec__grid {
    margin-top: 78px;
  }
}

.talk.blog-details-page {
  margin-top: 48px;
}

@media only screen and (min-width: 2300px) {
  .first-sec.blog-details {
    padding-bottom: 420px;
  }
}

@media only screen and (max-width: 550px) {
  .first-sec.blog-details {
    padding-bottom: 210px;
  }
}