.first-sec.job-details {
  @media only screen and (max-width: 1445px) {
    padding-bottom: 259px;
  }
  .bl {
    max-width: 496px;
    margin: 52px auto 0;
    h3 {
      font-weight: 300;
    }
  }

  .btn {
    display: inline-flex;
    margin-top: 21px;
    width: 100%;
    max-width: 175px;
  }
}

.job-detail {
  position: relative;
  z-index: 20;
  margin-top: -108px;
  &__wrapp {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    max-width: 745px;
    width: 100%;

    li {
      color: var(--white);
    }

    p {
      padding-top: ac(8px, 0px);
      padding-bottom: ac(24px, 10px);
    }

    .btn {
      margin-top: 36px;
      max-width: 175px;
      width: 100%;
    }

    .share {
      margin-top: 35px;

      p {
        padding-bottom: 0;
      }

      .social {
        padding-left: 0;
        margin-top: 12px;
        display: flex;
        align-items: center;
      }
    }
  }

  &__right {
    margin-top: 11px;
    margin-left: 20px;
    max-width: 288px;
    width: 100%;
  }

  .person-card {
    a {
      color: var(--white);
    }

    .bl .btn:hover {
      color: var(--white);
    }
  }
}

.person-card {
  width: 100%;

  &__img {
    width: 100%;
    overflow: hidden;
    border-radius: 28px;
    position: relative;

    &::before {
      position: relative;
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 87.6%;
    }

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .social {
    margin: 0;
    padding: 0;

    a {
      padding-bottom: 0;
    }
  }

  &__name {
    margin-top: 25px;
    display: flex;
    align-items: flex-start;

    h3 {
      margin-left: 11px;
      margin-top: -2px;
    }
  }

  a {
    padding-bottom: 5px;
    color: var(--black);
    &:hover {
      color: var(--main);
    }
  }

  .bl {
    margin-top: -4px;
    padding-left: ac(33px, 18px);

    &::before {
      top: 4px;
    }

    p {
      padding-bottom: 5px;
    }

    a:not(.btn) {
      font-size: ac(18px, 15px);
      display: inline-block;

      &:hover {
        transition: color .2s ease;
        color: var(--main);
      }
    }

    .btn {
      display: inline-flex;
      margin-top: 10px;
      max-width: 198px;
      width: 100%;
    }
  }
}

.recommended {
  position: relative;
  z-index: 20;
  padding-top: 90px;
  padding-bottom: 30px;

  &__wrapp {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  .job-card {
    padding-top: 46px;
    padding-bottom: 7px;
  }
}

.talk.job-details-page {
  margin-top: 93px;

  .cont::before {
    display: none;
  }
}

.job-page-form {
  input[type="file"] {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}

.job-detail__content h3.headline-subheader__careers, .job-detail__content .tier-two-page-content__text {
  background: transparent !important;
}

.job-detail__content li {
  color: white;
}

@media only screen and (max-width: 1420px) {
  .talk.job-details-page .wave {
    top: -100px;
  }
}

@media only screen and (max-width: 1024px) {
  .job-detail {
    margin-top: -70px;
    &__right {
      max-width: 235px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .talk.job-details-page {
    padding-top: 140px;

    .wave {
      top: -70px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .job-detail {
    margin-top: -105px;
  }

  .recommended__wrapp {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 10px;
  }
}

@media only screen and (max-width: 768px) {
  .job-detail {
    &__wrapp {
      flex-direction: column;
    }
    &__content {
      max-width: 100%;
    }
    &__right {
      margin-top: 40px;
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 640px) {
  .talk.job-details-page .wave {
    top: -30px;
  }
}

@media only screen and (max-width: 550px) {
  .first-sec.job-details {
    padding-bottom: 210px;
  }
  .job-detail {
    margin-top: -60px;
  }
  .recommended__wrapp {
    grid-template-columns: 1fr;
    grid-gap: 40px 10px;
  }
}