.footer {
  position: relative;
  z-index: 10;
  padding-bottom: 40px;
  &__logo {
    width: 185px;
    margin-bottom: 37px;
    transition: transform .2s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
  &__row {
    padding-top: ac(89px, 56px);
    display: flex;
    justify-content: space-between;
  }
  
  &__col {
    max-width: 310px;
    margin-right: 30px;
    
    &:nth-child(2) {
      max-width: 400px;
      margin-top: 35px;
      transform: translateX(59px);
    }
    
    &:nth-child(3) {
      max-width: 265px;
      margin-top: 44px;
      transform: translateX(59px);
    }

    &:last-child {
      max-width: 28px;
      margin-right: 0;
    }
    
    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 180%;
      padding-bottom: 0;
    }

    .bl {
      padding-left: ac(60px, 18px);
    }
  }
  a.footer__block:hover {
    text-decoration: underline;
  }
  &__block {
    margin-top: 3px;
    margin-bottom: 33px;

    p {
      display: inline;
      line-height: 187%;
    }
  }

  .link {
    color: var(--white);
    font-size: ac(16px, 16px);
    margin-bottom: 6px;
    font-weight: 400;
    &:nth-child(3) {
      margin-top: 38px;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  &__bottom {
    margin-top: 6px;
    padding: ac(35px, 34px) 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(255,255,255, .15);

    p, a {
      font-size: 14px;
      font-weight: 300;
    }
   
    &__left {
      display: flex;
      align-items: center;
      p {
        padding-bottom: 7px;
      }

      img {
        margin-left: 29px;
      }
    }

    &__nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      a {
        margin-left: ac(30px, 20px);
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.social {
  padding-left: ac(60px, 18px);
  font-size: 0;
  margin-top: 33px;
  a {
    width: 37px;
    height: 37px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main);
    border-radius: 5px;
    margin-right: 13px;
    margin-bottom: 13px;
    text-decoration: none !important;
    cursor: pointer;
    transition: background-color .2s ease;
    z-index: 1;
    
    &:hover {
      background-color: var(--primary);
    }

    span {
      font-size: 14px;

      &.icon-logo-whatsapp {
        font-size: 19px;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &-sm {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-top: 46px;
    a {
      width: 28px;
      height: 28px;
      margin-bottom: 6px;

      span {
        font-size: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .footer__bottom__nav a {
    margin-left: ac(24px, 10px);
  }
  .footer__col {
    &:nth-child(2), &:nth-child(3) {
      transform: none;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .footer__bottom__left img {
    width: 100px;
  }

  .footer__col:nth-child(3) {
    max-width: 220px;
  }
}

@media only screen and (max-width: 1024px) {
  .footer__bottom {
    flex-direction: column;
    
    &__nav {
      justify-content: center;
    }

    &__left {
      margin-bottom: 30px;
    }
  }

  .footer__col:nth-child(2) {
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  /* .footer__col:nth-child(2) {
    display: none;
  } */
  .footer__row {
    flex-wrap: wrap;
    .footer__col {
      &:first-child {
        order: 1;
      }
      &:nth-child(2) {
        order: 3;
        max-width: 280px;
      }
      &:nth-child(3) {
        order: 4;
      }
      &:nth-child(4) {
        order: 2;
      }
    }
  }

}

@media only screen and (max-width: 650px) {
  .footer__col {
    max-width: 100%;
  }
  /* .footer__col:nth-child(2) {
    display: none;
  } */
} 

@media only screen and (max-width: 550px) {
  /* .footer__col:nth-child(3) {
    display: none;
  } */
  .footer__col:last-child {
    max-width: unset;
  }

  .footer__row {
    flex-direction: column;
  }

  .social-sm {
    flex-direction: row;
    padding-left: ac(60px, 18px);
    margin-top: 25px;
  }
}

@media only screen and (max-width: 500px) {
  .footer__col .bl {
    padding-left: 30px;
  }

  .social {
    padding-left: 30px;
  }

  .footer__bottom {
    margin-top: 56px;

    &__left img {
      width: unset;
    }
    &__nav a {
      margin-left: 26px;
      font-size: 10px;
      margin-bottom: 17px;
    }
  }
}

@media only screen and (max-width: 376px) {
  .footer__bottom__nav a:nth-child(4) {
    margin-left: 0;
  }

  .footer__bottom__nav a:nth-child(5) {
    margin-left: 45px;
  }
}
