.sec-about.join-the-team {
  .sec-about {
    &__wrapp {
      margin-top: 3px;
    }
    &__img {
      height: 415px;
      min-width: 430px;
      width: 430px;
      transform: translate(142px,-139px);
    }
    &__content {
      max-width: 900px;
    }
  }
}

.sec-about-first {
  margin-top: 250px !important;
  margin-bottom: 200px;
}

.video-js {
  cursor: pointer;
  .vjs-big-play-button {
    width: 70px;
    height: 80px;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
    background-color: transparent;
    border: 0;
  }
}

.video-js:hover .vjs-big-play-button {
  background-color: transparent;

  .vjs-icon-placeholder:before {
    transform: scale(1.05);
  }
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: '';
  width: 70px;
  height: 80px;
  transition: .2s ease;
  background-image: url('../images/join-the-team/play.svg');
  background-repeat: no-repeat;
}

.vjs-poster {
  background-size: cover;
}

.vid1-dimensions {
  border-radius: 28px;
  overflow: hidden;
  width: 100%;
  transform: translateZ(0)
}

.vid1-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 45.4%;
}

.sec-about__videos {
  position: relative;
  z-index: 30;
  max-width: 896px;
  margin: 34px auto 0;
  &::before {
    position: absolute;
    content: '';
    width: ac(876px, 676px);
    height: ac(876px, 676px);
    background: radial-gradient(circle, rgba(209,46,37,0.7) 0%, rgba(5,36,83,0) 70%);
    border-radius: 100%;
    top: -160px;
    left: -50%;
    user-select: none;
    pointer-events: none;
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .prev {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  
  .sec-about__video {
    max-width: 100%;
    margin: unset;
    &::before {
      display: none;
    }
  }
}

.sec-about__video {
  position: relative;
  z-index: 30;
  max-width: 896px;
  margin: 34px auto 0;

  &::before {
    position: absolute;
    content: '';
    width: ac(876px, 676px);
    height: ac(876px, 676px);
    background: radial-gradient(circle, rgba(209,46,37,0.7) 0%, rgba(5,36,83,0) 70%);
    border-radius: 100%;
    top: -160px;
    left: -50%;
    user-select: none;
    pointer-events: none;
  }

  &.vid iframe {
    height: ac(504px, 390px);

    @mixin tab-sm {
      height: 50vw;
    }
  }

  .plyr {
    height: ac(504px, 390px);

    @mixin tab-sm {
      height: 50vw;
    }

    @mixin mob-lg {
      iframe {
        opacity: 0;
      }
    }
  }

  iframe {
    width: 100%;
    border-radius: ac(28px, 10px);
    position: relative;
    z-index: 1;

    @mixin tab-sm {
      height: 50vw;
    }
  }
}

.plyr {
  border-radius: ac(28px, 10px);
}

.benefits-slider.join-the-team {
  background-image: url('../images/join-the-team/bg.png');
  background-position: bottom;
  background-size: cover;
  position: relative;
  margin-top: 0;
  padding-top: 232px;
  padding-bottom: 89px;

  .wave {
    width: 100%;
    height: auto;
    position: absolute;
    top: -71px;
    left: 0;
    right: 0;
  }

  .cont.cont-lg {
    max-width: 1340px;
    width: perc(1340);
  }

  .benefits-slider__inner {
    margin-top: 71px;
    img {
      max-width: 83px;
    }
    h5 {
      width: 80%;
      margin-top: 20px;
    }
  }

  .benefits-slider__btns {
    justify-content: center;
    margin-top: 33px;
  }
}

.featured-jobs.join-the-team {
  background-image: none;
  margin-top: 0;
  padding-top: 180px;
  padding-bottom: 0;
  z-index: 99;
  .cont::before {
    position: absolute;
    content: '';
    width: ac(876px, 676px);
    height: ac(876px, 676px);
    background: radial-gradient(circle, rgba(209,46,37,0.7) 0%, rgba(5,36,83,0) 70%);
    border-radius: 100%;
    top: -125%;
    right: -30%;
    user-select: none;
    pointer-events: none;
  }
  .cont:after {
    right: 0;
    top: unset;
    bottom: -190%;
  }

  .job-card {
    padding-top: 45px;
    padding-bottom: 10px;
  }

  .featured-jobs__left {
    margin-top: -16px;
  }
}

.galler-two {
  position: relative;
  z-index: 99;
  margin-top: 35px;
  &__inner {
    display: flex;
    align-items: flex-start;
  }
  &__left, &__right {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 21px;
    padding-right: 8px;

    img {
      width: 100%;
      &:nth-child(3) {
        grid-column: 1 / 3;
      }
    }
  }

  &__right {
    padding-right: 0;
    padding-left: 8px;
    grid-template-columns: 1.3fr 2fr;
  }
}

.sec-about.join-the-team-2 {
  margin-top: 186px;

  .cont {
    &:after {
      left: unset;
      right: -35%;
    }
    &:before {
      left: -35%;
      right: unset;
    }
  }
  .sec-about {
    &__img {
      height: 433px;
      min-width: 447px;
      transform: translate(119px,-104px);
      width: 430px;


    }
    &__top__left {
      max-width: 685px;
      .bl {
        max-width: 685px;
      }
    }
    &__content {
      margin-top: 10px;
      margin-left: ac(152px, 0px);
      p {
        padding-bottom: ac(31px, 15px);
      }
    }
  }
}

.sec-about.join-the-team-3 {
  padding-top: ac(227px, 100px);
  position: relative;

  .decor-careers {
    position: absolute;
    user-select: none;
    pointer-events: none;

    &--first {
      right: ac(-136px, -30px);
      top: 0;
      width: ac(848px, 260px);

      &::before {
        position: relative;
        content: '#Orion Talent';
        font-size: ac(125px, 34px);
        font-weight: 700;
        opacity: .15;
        color: #fff;
      }
    }

    &--second {
      left: 0;
      bottom: -100px;
      transform: translateX(-57%);
      width: ac(1254px, 300px);
      
      @media screen and (max-width: 1200px) {
        bottom: 0px;
      }

      @media screen and (max-width: 800px) {
        transform: translateX(-10%);
      }

      &::before {
        position: relative;
        content: '#Orion Talent';
        font-size: ac(185px, 34px);
        font-weight: 700;
        opacity: .15;
        color: #fff;
      }
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .sec-about__video {
    margin-top: ac(120px, 40px);
  }

  .sec-about {

    &__wrap {
      position: relative;
      z-index: 1;
    }

    &__img {
      right: auto;
      left: 0;
      transform: translateX(0) translateY(-10%);
      height: ac(433px, 300px);
      min-width: ac(447px, 300px);
      width: ac(430px, 300px);
    }

    &__top {
      justify-content: flex-end;
    }

    &__top__left {
      @mixin media 1350 {
        max-width: 50%;
      }

      @mixin media 800 {
        max-width: 100%;
        width: 100%;
      }
    }

  }
}

.talk.join-the-team {
  margin-top: 146px;

  .talk__top {
    max-width: 440px;
  }

  .talk__left {
    width: 30%;
  }
  .talk__right {
    padding-top: 11px;
    width: 62%;
  }
}

.talk {
  h3.title-small {
    color: #fff;
  }
  .form-file {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .grid-form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: ac(35px, 20px);
    }

    .flex {
      grid-column: 1 / 3;
    }
    
    .btn {
      width: 175px;
      margin-top: ac(35px, 20px);
    }

    input[type='file'] {
      display: none;
    }

    label {
      position: static;
      transform: none;
      max-width: 100%;
      flex: 1;
      border-bottom: 1px solid var(--white);
      padding-bottom: 3px;
      margin-right: 15px;

      p {
        font-size: ac(30px, 21px);
        font-weight: 300;
        padding-bottom: 0;
        
        i {
          font-weight: 300;
          font-size: ac(30px, 21px);
          opacity: .6;
          margin-left: 15px;
          width: 176px !important;
          
          @media only screen and (max-width: 1100px) {
            width: 140px!important;
          }
          
          @media only screen and (max-width: 430px) {
            width: 280px!important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 2050px) {
  .benefits-slider.join-the-team {
    padding-top: 15%;
    .wave {
      top: -130px;
    }
  }
}


@media only screen and (max-width: 1340px) {
  .sec-about.join-the-team {
    margin-top: -80px;
    .sec-about__content {
      max-width: 760px;
    }
  }

  .sec-about.join-the-team-2 {
    margin-top: 180px;
  }
}

@media only screen and (max-width: 1200px) {
  .sec-about.join-the-team {
    margin-top: -40px;
    .sec-about__content {
      max-width: 645px;
    }
  }

  .benefits-slider.join-the-team .wave {
    top: -45px;
  }

  .sec-about.join-the-team-2 {
    margin-top: 180px;

    .sec-about__top__left {
      max-width: 600px;
    }

    .sec-about__content {
      margin-left: 0;
    }
  }

  .featured-jobs__left .bl .btn {
    padding-top:15px;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 1080px) {
  .sec-about.join-the-team .sec-about__content {
    padding-left: 85px;
    max-width: 567px;
  }

  .sec-about.join-the-team-2 .sec-about__top__left {
    max-width: 415px;
  }
}

@media only screen and (max-width: 900px) {
  .benefits-slider.join-the-team .wave {
    top: 0px;
  }

  .featured-jobs__left .bl .btn {
    padding-top:9px;
    padding-bottom: 9px;
  }
}

@media only screen and (max-width: 800px) {
  .benefits-slider.join-the-team .wave {
    top: -60px;
    left: -50%;
    width: 200%;
  }

  .sec-about.join-the-team-2 .sec-about__top__left {
    max-width: 100%;
  }
  .sec-about.join-the-team-2 .sec-about__content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .sec-about.join-the-team {
    margin-top: -90px;
  }

  .featured-jobs.join-the-team {
    padding-top: 60px;
    .cont:before, .cont:after {
      display: none;
    }
  }

  .galler-two__inner {
    flex-wrap: wrap;
  }
  .galler-two__left, .galler-two__right {
    width: 100%;
    padding: 0;
    margin-bottom: 21px;
  }

  .sec-about.join-the-team-2 {
    margin-top: 40px;
  }

  .video-js .vjs-big-play-button {
    top: calc(50% - 40px);
  }
}

@media only screen and (max-width: 660px) {
  .talk.join-the-team {
    .talk__wrapp {
      flex-direction: column;
    }
    .talk__right, .talk__left {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 550px) {
  .sec-about.join-the-team .sec-about__content {
    padding-left: 0;
  }

  .video-js .vjs-big-play-button {
    width: 45px;
    height: 50px;
    top: calc(50% - 25px);
  }

  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    width: 46px;
    height: 52px;
    background-size: cover;
  }

  .benefits-slider.join-the-team .wave {
    top: 0;
  }

  .sec-about__video:before {
    display: none;
  }
}

@media only screen and (max-width: 440px) {
  .talk .form-file {
    flex-direction: column;

    .btn {
      display: inline-flex;
      margin-top: 20px;
      max-width: 170px;
    }
  }
}

@media only screen and (max-width: 430px) {
  .talk .form-file label p i {
    margin-left: 0;
  }
  .talk .form-file .flex {
    grid-column: 1/1
  }
  .talk .form-file .grid-form {
    width: 100%;
    grid-template-columns: 1fr;
  }
}

.our-benefits-section {
  padding-top: ac(180px, 60px);
  position: relative;
  z-index: 10;

  &.microsite-var {
    z-index: 5;
    padding-top: ac(140px, 60px);
  }

  &:before {
    background: radial-gradient(circle,rgba(209,46,37,.7) 0,rgba(5,36,83,0) 70%);
    border-radius: 100%;
    content: "";
    height: min(max(calc(447.42857px + 29.7619vw),676px),876px);
    left: 0;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    user-select: none;
    width: min(max(calc(447.42857px + 29.7619vw),676px),876px);
    transform: translateX(-50%) translateY(50%);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    h2 {
      padding-bottom: ac(40px, 30px);
    }
  }

  .benefits-thumbs-slider {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      background-image: url("../images/decor-statistics-blue.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: ac(346px, 173px);
      height: ac(330px, 165px);
      right: ac(40px, 0px);
      bottom: ac(74px, 5px);

      @mixin mob-xl {
        opacity: .1;
      }
    }


    .benefits-thumbs-slider-logo {
      width: 40.67%;
      max-width: 40.67%;
      overflow: hidden;
      padding-right: 15px;
      margin: 0;
      height: 272px;

      @mixin desk-sm {
        width: 45%;
        max-width: 45%;
      }

      @mixin tab-sm {
        width: 47%;
        max-width: 47%;
      }

      @mixin min-media 652 {
        .swiper-wrapper {
          width: 100%;
          max-width: 100%;
        }
      }

      @media screen and (max-width: 651px) {
        height: unset;
      }

      @mixin mob-xl {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
      }

      &__item {
        border: 3px solid var(--primary);
        background: var(--primary);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: ac(21px, 15px);
        border-radius: 8px;
        height: 120px;
        max-height: 120px;
        cursor: pointer;
        transition: background-color .25s ease, transform .25s ease;

        margin-bottom: 16px;
        /* &:not(:last-child) {
        } */

        &:hover {
          transform: translateX(ac(15px, 10px));
        }

        &.swiper-slide-thumb-active {
          background: transparent;
        }

        @mixin mob-xl {
          border: none;
          border-radius: 0;
          background: transparent;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 0;
          }

          &:hover {
            transform: none;
          }
        }
      }

      &__icon {
        width: ac(72px, 60px);
        height: ac(72px, 60px);
        margin-right: ac(24px, 12px);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__text {
        p {
          padding-bottom: 0;
          font-size: ac(21px, 15px);
          font-weight: 600;
          letter-spacing: 0;
          line-height: 135%;
        }

        @mixin mob-xl {
          display: none;
        }
      }
    }

    .benefits-thumbs-slider-info {
      width: 49.17%;
      max-width: 49.17%;
      margin: 0;

      &__item {
        padding-left: 16px;
        p {
          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      &__btns {

        margin-top: 30px;
        display: flex;

        .btn-arrow {
          width: ac(54px, 36px);
          height: ac(54px, 36px);

          &:first-child {
            svg {
              transform: rotate(-180deg);
            }
          }
        }

        @mixin mob-xl {
          justify-content: center;
        }
      }

      @mixin mob-xl {
        margin-top: 20px;
        width: 100%;
        max-width: 100%;
      }
    }

    @mixin mob-xl {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}