.first-sec {
  filter: none !important;
  position: relative;
  width: 100%;
  padding-top: ac(266px, 120px);
  padding-bottom: 15%;
  
  @media only screen and (max-width: 1445px) {
    padding-bottom: 235px;
  }

  &::before {
    position: absolute;
    content: '';
    width: ac(830px, 400px);
    height: ac(830px, 400px);
    background: radial-gradient(circle, rgba(14,36,80,1) 0%, rgba(12,36,81,1) 20%, rgba(5,36,83,0) 70%);
    left: -10%;
    top: -50%;
    z-index: 10;
    border-radius: 100%;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 99%;
    object-fit: cover;
    display: block;
    pointer-events: none;
  }
  
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 99%;
    object-fit: cover;
    display: block;
    filter: grayscale(100%);
    pointer-events: none;
  }

  &__content {
    position: relative;
    z-index: 10;
  }

  .wave {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 10;
    bottom: 0;

    @media only screen and (max-width: 800px) {
      width: 200%;
      left: -50%;
    }

    path {
      fill: #052453;
    }
  }
}

.sec-about.about-us {
  position: relative;
  z-index: 10;

  .sec-about {
    .cont:before {
      right: -45%;
      top: -225px;
    }
    &__top {
      display: flex;
      max-width: 100%;
  
      &__left {
        max-width: 670px;

        .bl {
          max-width: 670px;
        }
      }
    }
    &__wrapp {
      margin-top: 35px;
    }
    &__content {
      max-width: 890px;
      padding: 7px 0 0 152px;
    }
    &__img {
      position: absolute;
      right: 0;
      margin-left: 0;
      transform: translate(142px,-114px);
      min-width: 489px;
      width: 489px;
      height: 472px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }
  }
}

.sec-cards {
  background-image: url('../images/about-us/cards-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 289px;
  padding-bottom: 129px;

  .wave {
    position: absolute;
    top: -25%;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    
    @media only screen and (max-width: 1925px) {
      top: -110px;
    }

    @media only screen and (max-width: 1445px) {
      top: -110px;
    }
  }

  .cont {
    max-width: 1117px;
  } 

  &__wrapp {
    display: flex;
  }
}

.our-card {
  max-width: 542px;
  width: 100%;
  background-color: var(--primary);
  border-radius: 28px;
  margin: 0 8px;
  padding: 42px 52px 21px;

  .bl {
    padding-left: ac(59px, 18px);

    &::before {
      height: calc(100% - 28px);
    }
  }
}

.join {
  background-color: var(--primary);
  padding-top: 121px;
  position: relative;
  z-index: 20;
  &__wrapp {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    max-width: 360px;
    margin-right: 40px;
    padding-top: 4px;
    padding-bottom: 40px;
    .bl {
      margin-top: 37px;
      padding-left: ac(59px, 18px);
    }

    .btn {
      display: inline-flex;
      padding: ac(14px, 8px)  ac(42px, 18px);
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-gap: 20px;
    transform: translateY(142px);
    margin-top: -142px;

    .gallery__img {
      margin: 0;
      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
      }
      &:nth-child(2) {
        grid-area: 1 / 2 / 3 / 3;
      }
      &:nth-child(3) {
        grid-area: 2 / 1 / 4 / 2;
      }
      &:nth-child(4) {
        grid-area: 3 / 2 / 4 / 3;
      }
    }
  }
}

.specialisms.about-us {
  margin-top: ac(-500px, -350px);
  padding-top: ac(327px, 200px);

  h2.text-center {
    position: relative;
    z-index: 21;
  }

  .specialisms__grid {
    position: relative;
    z-index: 20;

    &::after {
      position: absolute;
      content: '';
      width: ac(1100px, 642px);
      height: ac(1100px, 642px);
      background: radial-gradient(circle, rgba(41,86,226,.7) 0%, rgba(41,86,226,0) 60%);
      border-radius: 100%;
      top: -406px;
      right: 54%;
      transform: translateX(50%);
      user-select: none;
      pointer-events: none;
      z-index: -1;
    }
  }

  @mixin tab-sm {
    margin-top: -300px;
  }
}

.services-bg {
  background-image: url('../images/about-us/bg-services.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
}

.services {
  position: relative;
  .wave {
    position: absolute;
    top: -29px;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
  }
  &__wrapp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 350px;
  }

  &__left {
    max-width: 380px;
    margin-top: -28px;

    .btn {
      display: inline-flex;
      width: 200px;
    }
    .bl {
      padding-left: ac(59px, 18px);
      margin-top: 37px;

    }
  }

  &__grid {
    display: flex;
    flex-direction: column;
    max-width: 744px;
    width: 100%;
    margin-left: 20px;
  }

  &__card {
    background-color: var(--primary);
    display: block;
    max-width: 440px;
    width: 100%;
    font-size: ac(30px, 22px);
    padding: 20px 20px 21px;
    border-radius: 28px;
    text-align: center;
    margin-bottom: 23px;

    &:nth-child(even) {
      margin-left: auto;
    }
  }
}

.talk.about-us {
  background-image: none;
  margin-top: -119px;

  .cont:before {
    display: none;
  }
}

@media only screen and (max-width: 1350px) {
  .sec-about.about-us .sec-about {
    &__content {
      max-width: 65%;
    }
    &__top__left {
      max-width: 65%;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .sec-about.about-us .sec-about__img {
    height: 380px;
    width: 392px;
    min-width: 392px;
    max-width: 392px;
    transform: translate(131px,-55px);
  }

  .sec-cards .wave {
    top: -50px;
  }
}

@media only screen and (max-width: 1100px) {
  .services {
    &__wrapp {
      padding-top: 260px;
    }
    .wave {
      top: 0;
    }
  }

  .talk.about-us {
    margin-top: -60px;
  }
} 

@media only screen and (max-width: 1040px) {
  .services__card:nth-child(2n) {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 960px) {
  .sec-about.about-us .sec-about__content {
    padding: 7px 0 0 60px;
  }
} 

@media only screen and (max-width: 900px) {
  /*.specialisms.about-us {
    padding-top: 190px;
  }*/
  .services__wrapp {
    padding-top: 260px;
  }
  .talk.about-us {
    margin-top: -20px;
  }
}


@media only screen and (max-width: 850px) {
  .join__left {
    max-width: 305px;
  }
}

@media only screen and (max-width: 800px) {
  .sec-about.about-us .sec-about {
    &__img {
      display: none;
    }
    &__top__left {
      max-width: 100%;
    }
    &__content {
      max-width: 100%;
    }
  }

  .sec-cards .wave, .services .wave {
    width: 200%;
    left: -50%;
  }
}

@media only screen and (max-width: 768px) {
  .join {
    &__wrapp {
      flex-wrap: wrap;
    }
    &__left {
      max-width: 100%;
      margin-bottom: 50px;
      margin-right: 0;
    }
    &__grid {
      width: 100%;
      max-width: 100%;
    }
  }

  .specialisms.about-us .specialisms__grid::after {
    display: none;
  }

  .services {
    .wave {
      top: -75px;
    }
    &__wrapp {
      flex-wrap: wrap;
    }
    &__left {
      margin-bottom: 50px;
    }
    &__grid {
      margin-bottom: 80px;
      margin-left: 0;
    }
    &__card:nth-child(2n) {
      margin-left: auto;
    }
  }

  .talk.about-us {
    margin-top: -110px;
  }
}

@media only screen and (max-width: 700px) {
  .sec-cards {
    padding-top: 250px;
    &__wrapp {
      flex-wrap: wrap;
  
      .our-card {
        max-width: 100%;
        margin: 0 0 16px 0;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .services {
    &__wrapp {
      padding-top: 220px;
    }
    &__card {
      padding: 16px 14px 16px;
      max-width: 380px;
      border-radius: 20px;
      font-size: 18px;
    }
  }
  .services-bg {
    background-size: 200% 150%;
    background-position: left 100% center;
  }
}

@media only screen and (max-width: 450px) {
  .sec-about.about-us .sec-about__content {
    padding-left: 0;
  }
  .sec-cards {
    padding-top: 160px;
  }
  /*.specialisms.about-us {
    padding-top: 100px;
  }*/

  .services__card {
    font-size: 16px;
    max-width: 245px;
    padding: 13px 14px;
  }
  .services .wave {
    top: -30px;
  }
}

@media only screen and (max-width: 420px) {
  .sec-cards {
    padding-bottom: 55px;
  }
  .join.about-page {
    padding-top: 55px;
  }
}

@media only screen and (max-width: 400px) {
  .sec-about.about-us {
    padding-top: 80px;

    .cont::after {
      top: -50px;
    }
  }

}

@media only screen and (max-width: 360px) {
  .sec-cards {
    padding-top: 100px;
  }
}

.history-section {
  padding-top: ac(265px, 120px);
  padding-bottom: ac(498px, 200px);
  position: relative;
  overflow: hidden;

  + .specialisms {
    margin-top: ac(-330px, -140px) !important;
    padding-bottom: 10px;
  }

  @media only screen and (min-width: 2490px) {
    &::before {
      position: absolute;
      content: '';
      background-color: #052453;
      height: 100%;
      width: 2vw;
      left: 0;
      top: 0;
      z-index: 10;
    }
  }


  /*&:before {
    background: radial-gradient(circle,rgba(41,86,226,.7) 0,rgba(41,86,226,0) 60%);
    border-radius: 100%;
    content: "";
    height: min(max(calc(118.57143px + 68.15476vw),642px),1100px);
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateX(-50%) translateY(50%);
    user-select: none;
    width: min(max(calc(118.57143px + 68.15476vw),642px),1100px);
    z-index: -1;
  }*/

  &.specialism-var {
    margin-top: ac(80px, 40px);
    padding-bottom: ac(120px, 90px);


  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 25%;
      right: 0;
      bottom: 0;
      background: linear-gradient(127.23deg, #FF0D1B 18.05%, #052453 65.02%);
      opacity: 0.8;
      z-index: 1;

      @mixin tab-sm {
        left: 10%;

      }
    }

    &.microsite {
      &:before {
        opacity: 1;
        background: linear-gradient(180deg, #052453 0%, #052453 5%, rgba(255, 13, 27, 0.67) 43.10%, #052453 95%, #052453 100%);
      }
    }

    &:after {
      content: "";
      bottom: 0;
      left: 0;
      right: 0;
      height: 500px;
      position: absolute;
      background: linear-gradient(180deg, color-mod(#fff a(0%)) 0%, #052453 100%);
    }
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .5;

  }

  &__decor {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    transform: translateX(-100%);
    z-index: 1;
    height: 100%;
    max-width: 150%;
    object-fit: cover;
    object-position: right;
  }

  &__wrap {
    position: relative;
    z-index: 2;

    &:before {
      display: block;
      position: absolute;
      content: "";
      background-image: url("../images/decor-statistics-blue.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: ac(88px, 44px);
      height: ac(84px, 42px);
      left: -80px;
      top: 52%;
    }
  }

  &__title {
    padding-left: 16px;
  }

  .history-slider {
    height: ac(550px, 450px);
    width: 100%;
    max-width: 100%;
    overflow: visible;
    clip-path: inset(0 -100px 0 -100px);
    position: relative;
    transform: translateX(ac(30px, 0px));
    transition: margin-bottom .3s ease;

    &.last-slide {
      margin-bottom: ac(-180px, 0px);
    }


    &__item {
      height: ac(174px, 130px);
      transition: height .25s ease, opacity .3s ease;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      /*margin-bottom: 30px;*/
      user-select: none;

      &:before,
      &:after {
        content: "";
        position: absolute;
        left: ac(46px, 25px);
        width: 16px;
        height: 45px;

        @mixin mob-xl {
          width: 11px;
          height: 31px;
        }
      }

      &:before {
        background-image: url("../images/about-us/history-decor-bottom.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        top: ac(0px, -2px);
        transform: translateY(-35%);

        @mixin mob-lg {
          transform: translateY(-50%);
        }
      }

      &:after {
        background-image: url("../images/about-us/history-decor-top.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        bottom: ac(0px, -2px);
        transform: translateY(35%);

        @mixin mob-lg {
          transform: translateY(50%);
        }
      }

      &:first-child {
        &:before {
          content: none;
        }
      }

      &:last-child {
        &:after {
          content: none;
        }
      }

      .history-slider-date {
        font-size: ac(54px, 40px);
        font-weight: 700;
        line-height: 122%;
        margin-right: ac(69px, 30px);
        transition: font .25s ease, transform .25s ease, opacity .3s ease;
        opacity: .3;
        white-space: nowrap;

        @mixin mob-xl {
          font-size: 24px;
          margin-right: 16px;
        }

        @mixin mob-lg {
          padding-bottom: 8px;
        }
      }

      .history-slider-text {
        max-width: ac(320px, 240px);
        transition: max-width .25s ease, transform .25s ease, opacity .3s ease;
        opacity: .3;
        @mixin max-line-length 4;
        padding-left: 16px !important;

        p {
          transition: font .25s ease, transform .25s ease;
          font-size: ac(18px, 16px);
          font-weight: 500;
          line-height: 138%;
          padding-bottom: 0;

          @mixin mob {
            line-height: normal;
          }

          @mixin mob-lg {
            font-size: 18px;
            font-weight: 800;
          }
        }

        @mixin mob-lg {
          max-width: 280px;
        }
      }


      .date-decor-first,
      .date-decor-second {
        font-size: ac(210px, 60px);
        font-weight: 800;
        line-height: 0.8;
        opacity: 0;
        color: var(--white);
        position: absolute;
        transition: opacity .25s ease, transform .3s ease;
      }

      .date-decor-first {
        top: 0;
        right: ac(35px, 0px);
        transform: translateX(50%) translateY(-90%);

        @mixin mob-lg {
          transform: translateX(50%) translateY(-50%);
        }
      }

      .date-decor-second {
        bottom: 0;
        right: ac(140px, 40px);
        transform: translateX(50%) translateY(90%);

        @mixin mob-lg {
          transform: translateX(50%) translateY(50%);
        }
      }

      &.swiper-slide-active {
        height: ac(210px, 170px);

        .history-slider-date {
          font-size: ac(80px, 40px);
          font-weight: 800;
          line-height: 120%;
          transform: translateX(ac(-27px, -10px));
          opacity: 1;

          @mixin mob-xl {
            font-size: 24px;
            transform: translateX(-5px);
          }

          @mixin mob-lg {
            transform: none;
          }
        }

        .history-slider-text {
          max-width: ac(900px, 304px);
          opacity: 1;

          p {
            font-size: ac(44px, 20px);
            font-weight: 800;

            @mixin mob-lg {
              font-size: 18px;
            }
          }

          @mixin mob-lg {
            max-width: 280px;
          }
        }

        .date-decor-first,
        .date-decor-second {
          opacity: .15;
        }

        .date-decor-first {
          transform: translateX(0) translateY(-90%);
        }

        .date-decor-second {
          transform: translateX(0) translateY(90%);
        }
      }

      @mixin mob-lg {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }


    @mixin mob-lg {
      height: 590px;
    }

  }

  @mixin tab-sm {
    padding-top: 210px;
  }
}