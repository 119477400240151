.first-sec.news-page {
  @media only screen and (max-width: 1445px) {
    padding-bottom: 261px;
  }
}

.gallery.news-page {
  padding-top: 0;
  position: relative;
  z-index: 10;
  margin-top: 72px;
}

.news-sec {
  margin-top: -20px;
  position: relative;
  z-index: 20;

  &.under-hero {
    margin-top: ac(-120px, -40px);
  }

  &__top {
    display: flex;
    /* justify-content: flex-end; */
    justify-content: space-between;
    align-items: center;

    .prev {
      svg {
        transform: rotate(180deg);
      }
    }

    .select2-container {
      max-width: 185px;
      min-width: 157px;
      width: auto !important;

      @mixin mob-lg {
        height: 30px;
      }
    }

    .select2-container .select2-selection--single {
      border: 0;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: var(--white);
      font-weight: 800;
      font-size: ac(18px, 16px);
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      background-image: url('../images/jobs/arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 13px;
    }
  }

  &__grid {
    padding-bottom: 30px;
    margin-top: 62px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 44px 60px;

    &.swiper {
      display: flex;
      grid-gap: unset;
    }
  }

  &__only-grid {
    width: 100%;
    padding-bottom: 30px;
    margin-top: ac(62px, 40px);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ac(44px, 24px) ac(60px, 24px);

    .news-card {
      max-width: 100%;
    }

    @mixin tab-md {
      grid-template-columns: repeat(2, 1fr);
    }

    @mixin mob-lg {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .news-card {
    /* max-width: 100%; */
    transition: transform .2s ease;
    max-width: 360px;

    /* &:hover {
      transform: translateY(-10px);
    } */ 
  }

  .jobs__pagination {
    margin-top: 14px;
  }
}

.talk.news-page {
  margin-top: 111px;
}

@media only screen and (max-width: 1300px) {
  .gallery.news-page {
    /* margin-top: -80px; */
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1024px) {
  .news-sec__grid {
    grid-gap: 44px 20px;
  }
  .news-sec {
    margin-top: -70px;
  }
}

@media only screen and (max-width: 900px) {
  .news-sec__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 800px) {
  .news-sec {
    margin-top: -50px;
  }
  .gallery.news-page {
    /* margin-top: -110px; */
  }
}

@media only screen and (max-width: 750px) {
  .news-sec.under-hero {
    margin-top: 100px;
  }
}


@media only screen and (max-width: 550px) {
  .news-sec {
    margin-top: -70px;
  }
  .gallery.news-page {
    /* margin-top: -50px; */
    margin-top: 0px;
  }
  .news-sec__grid {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .news-sec {
    margin-top: -30px;
  }
}

@media only screen and (max-width: 375px) {
  .talk.news-page {
    padding-top: 120px !important;
    .wave-mob {
      top: -130px; 
    }
  }
}

@media only screen and (max-width: 360px) {
  .first-sec.news-page {
    padding-top: 170px;
    padding-bottom: 190px;
  }
}