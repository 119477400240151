.fp-watermark {
  display: none;
}

* {
  font-family: var(--font-main)
}

html {
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
  caret-color: red;
}


body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--bg);
  color: var(--white);
  overflow-x: hidden;

  &.disabled {
    overflow-y: scroll;
    position: fixed;

    width: 100%;
  }
}

.sort-jobs {
  opacity: 0;
}

.snackbar-container {
  z-index: 9999999;
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.wave {
  pointer-events: none;
}

.main {
  flex-grow: 1;
}

%default-transition {
  transition: ease-in-out 0.25s;
}

.select {
  -webkit-appearance: none;
}

.select2-container {
  /* width: 100% !important; */
}

.select2-container--default .select2-selection--single {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--white);
}

.select2-container .select2-selection--single {
  height: 36px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: var(--white);
  padding-left: 0;
  font-size: 18px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  background-image: url('../images/chevron-down-outline.svg');
  background-position: 100% 100%;
  b {
    display: none;
  }
}

.select2-container--default .select2-results__option--selected {
  background-color: var(--bg);
}

.select2-dropdown {
  border-radius: 0;
  background-color: var(--primary);
  border: 0;
}

.cont {
  margin: 0 auto;
  max-width: 1200px;
  width: perc(1200);

  @mixin tab-md {
    width: 89%;
  }

  @media screen and (max-width: 370px) {
    width: 90% !important;
  }
}

textarea {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #0f213d; 
  }
  &::-webkit-scrollbar-thumb {
    background: var(--main); 
  }
}

section {
  position: relative;
}

.bl {
  padding-left: ac(49px, 18px);
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 1px;
    height: 100%;
    background-color: var(--white);
    top: 0;
    left: 0;
  }
}

.hero {
  padding-top: ac(60px, 120px);
  padding-bottom: ac(180px, 200px);
  position: relative;
  align-items: center;
  min-height: ac(780px, 600px);
  height: 100vh;
  max-height: 1050px;
  display: flex;
  justify-content: flex-start;

  &.home-hero {
    padding-top: ac(288px, 120px);

    &:before {
      transform: translateY(-30px);
    }
  }

  &.services-hero {
    &:before {
      transform: translateY(-30px);
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: -100px;
    left: -100px;
    right: -100px;
    bottom: -80px;
    background: linear-gradient(180deg,#fff, #fff 75%, color-mod(#fff a(0%)) 100%);
    filter: blur(20px);

  }

  /*&.isSafari {
    &:before {
      @mixin tab-sm {
        filter: blur(30px);
      }
    }
  }*/

  h1, h2, h3, h4, h5, h6, p {
    color: var(--primary);
  }

  .cont {
    position: relative;
    z-index: 1;
    /*&::after {
      position: absolute;
      content: '';
      width: ac(1100px, 642px);
      height: ac(1100px, 642px);
      background: radial-gradient(circle, rgba(41,86,226,.6) 0%, rgba(41,86,226,0) 60%);
      border-radius: 100%;
      bottom: -65%;
      left: -50%;
      z-index: 5;
      user-select: none;
      pointer-events: none;
    }*/
  }

  .wave {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: ac(-158px, 0px);
  }

  .wave-mob {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &__wrapp {
    position: relative;
    display: flex;
    padding-top: 0;
    z-index: 10;

    &.hero-new {
      .hero__img {
        margin-top: -100px;
        border-radius: 0;
        overflow: visible;
        width: ac(667px, 350px);
        height: ac(683px, 360px);
  
        img {
          display: block;
          object-fit: cover;
        }
      }
      @mixin media 1280 {
        .hero__content {
          width: 700px;
        }

        .hero__img {
          width: ac(567px, 350px);
          height: ac(583px, 360px);
          right: -120px;

          .hero-figure-container .hero-figure {
            width: ac(186.57px, 93.285px);
            height: ac(221.87px, 110.935px);
          }

          @mixin mob-lg {
            width: 100%;
            height: 300px;
            margin-bottom: -30px;
          }
        }
      }

      @mixin media 1240 {
        .hero__content {
          width: 600px;
        }
      }

      @mixin media 880 {
        .hero__content {
          width: 500px;
          max-width: 100%;
        }
        .hero__img {
          margin-top: -25px;
        }
      }

      @mixin media 750 {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 50px;

        .hero__content {
          width: 390px;
          z-index: 4;
        }

        .hero__img {
          /* margin-top: 30px; */
          position: absolute;
          bottom: auto;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: 255px;
          height: 300px;
          z-index: 3;

          .hero-figure-container .hero-figure {
            &.hero-figure-second {
              top: 3%;
              transform: rotate(-26.1deg);
            }

            &.hero-figure-third {
              bottom: 15%;
              left: 50%;
              transform: rotate(-40deg);
            }
          }
        }
      }

      @mixin media 650 {
        .hero__img {
          height: 240px;
          width: 210px;
          .hero-figure-container .hero-figure {
            width: 77px;
            height: 92px;
          }
        }
        .hero__content {
          width: 290px;

          .hero__block .btn {
            margin-bottom: 10px;
          }
        }
      }

      @mixin media 400 {
        padding-top: 0;
        .hero__img {
          right: -35px;
        }
      }

      @mixin media 360 {
        .hero__img {
          right: -72px;
        }
      }
    }
  }

  &__content {
    width: 765px;
  }

  &__img {
    z-index: 10;
    position: absolute;
    width: ac(700px, 350px);
    height: ac(700px, 350px);
    right: -80px;
    top: 0;
    border-radius: 100%;
    overflow: hidden;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      /* transform: rotate(47deg); */
    }

    .hero-figure-container {
      width: 100%;
      height: 100%;
      position: relative;

      .hero-figure {
        position: absolute;
        /*width: ac(186.57px, 93.285px);
        height: ac(221.87px, 110.935px);*/
        width: ac(259px, 93.285px);
        height: ac(308px, 110.935px);
        mask-image: url('../images/hero/figure.svg');
        mask-size: 100%;
        mask-repeat: no-repeat;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.hero-figure-first {
          top: 0;
          transform: translateY(-8%) translateX(55%) rotate(15deg);
          left: 0;
        }

        &.hero-figure-second {
          right: 0;
          top: 15%;
          transform: rotate(-45deg);
        }

        &.hero-figure-third {
          right: auto;
          bottom: 12%;
          left: 16%;
          transform: rotate(-15deg);
        }
      }
    }
  }

  &__block {
    margin-top: 26px;
    max-width: 527px;

    &::before {
      top: 8px;
      height: calc(100% - 8px);
    }

    p {
      font-size: ac(26px, 15px);
      letter-spacing: 0;
    }

    .btn {
      width: ac(186px, 174px);
      font-size: ac(16px, 15px);
      padding: 14px 16px;

    }
  }

  &.internal-hero {
    padding-top: ac(150px, 120px);

    .hero__content {
      width: 60.50%;

      @mixin mob-xl {
        width: 100%;
      }

      @mixin media 650 {
        width: 280px !important;
      }

      @mixin media 400 {
        padding-top: 40px;
      }
    }

    &.services-hero {
      padding-top: ac(110px, 120px);
    }

    .hero__img {
      width: ac(528px, 300px);
      height: ac(546px, 300px);
      top: ac(5px, 80px);
      right: -25px;
      
      @mixin media 650 {
        height: 240px;
        width: 210px;

        .hero-figure-container .hero-figure {
          height: 92px !important;
          width: 77px !important;
        }
      }

      @mixin media 560 {
        right: -85px;
        top: -91px!important;
      }

      @mixin media 370 {
        right: -90px;
      }

      &.with-about-as {
        top: ac(-10px, 80px);
      }

      .hero-figure-container {
        .hero-figure {
          &.hero-figure-first {
            top: 50%;
            left: 0;
            transform: translateY(-50%) rotate(35deg);
          }

          &.hero-figure-second {
            top: 0;
            right: 0;
            transform: rotate(-37deg);
          }

          &.hero-figure-third {
            right: 0;
            bottom: 0;
            transform: rotate(-46deg);
            left: auto;
          }
        }
      }

      &.specialism-var {
        top: ac(100px, 115px);
        transform: rotate(-17deg) translateX(10.5%) translateY(-1%);

        .hero-figure-container {
          .hero-figure {
            &.hero-figure-first {
              left: 9%;
              top: 50%;
              transform: translateY(-50%) translateX(-15%) rotate(35deg);
            }
          }
        }

        @mixin mob-xl {
          transform: rotate(-7deg) translateX(10.5%) translateY(-1%);
        }
      }

      .hero-figure-container {

        .hero-figure {
          width: ac(186.57px, 93.285px);
          height: ac(221.87px, 110.935px);

          &.hero-figure-first {
            left: 9%;
            top: 50%;
            transform: translateY(-85%) rotate(35deg);
          }

          &.hero-figure-second {
            top: 0;
            right: 4%;
            transform: rotate(-37deg) translateY(-20%);
          }

          &.hero-figure-third {
            bottom: 17%;
            right: 16%;
            transform: rotate(-46deg);
          }
        }
      }

      @mixin mob {
        /* width: 95%; */
      }
    }

    .hero__content {
      position: relative;

      &:before {
        z-index: -1;
        content: "";
        position: absolute;
        background-image: url("../images/decor-hero.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: ac(396px, 198px);
        height: ac(376px, 188px);
        left: 27px;
        bottom: 0;

      }
    }

    + .sec-about.specialisms-page-2 {
      margin-top: -40px;

      @mixin mob-xl {
        margin-top: -60px;
      }
    }
  }

  &.top-right-aura {
    position: relative;

    &:after {
      background: radial-gradient(circle,rgba(41,86,226,.7) 0,rgba(41,86,226,0) 60%);
      border-radius: 100%;
      content: "";
      height: ac(600px, 356px);
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      user-select: none;
      width: ac(600px, 356px);
      transform: translateX(50%) translateY(-50%);
      opacity: .5;
    }
  }

  &.microsite-hero {
    padding-top: ac(80px, 120px);
    padding-bottom: ac(140px, 200px);
    height: auto;
    min-height: ac(660px, 500px);

    .hero__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &:before {
        transform: translateY(-34%) translateX(2%);
      }
    }

    .hero__logo {
      width: ac(400px, 290px);
      max-width: 100%;
      max-height: ac(400px, 300px);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &.isSafari {
      &:before {
        filter: none;
      }
    }

  }

  &.blogs-page {
    @mixin media 480 {
      .hero__content {
        width: 250px!important;
      }
    }
  }

  @mixin media 750 {
    padding-bottom: 80px;
    min-height: auto;
    height: auto;
  }
}

.our-statistics-section {
  padding-top: ac(180px, 60px);
  padding-bottom: ac(203px, 60px);
  position: relative;
  z-index: 10;

  &__bg {
    @mixin aspect-ratio 1440, 356;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    mask-image: url('../images/decor-statistics-2.svg');
    mask-size: 100%;
    mask-repeat: no-repeat;
    /*clip-path: url("#statistics-svg-1");*/

    img {
      object-position: 50% 62%;
    }

    &:after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      background: linear-gradient(90deg, #1A264C 6.14%, #5F3076 44.81%, #E40512 94.08%);
      opacity: 0.86;
    }
  }

  &__wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;

    h2 {
      padding-bottom: 8px;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @mixin tab-md {
      grid-template-columns: repeat(3, 1fr);
    }

    @mixin mob-xl {
      grid-template-columns: repeat(2, 1fr);
    }

    @mixin mob {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }

    @mixin media 360 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__text {
    grid-area: 1 / 1 / 2 / 3;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      max-width: 440px;
      padding-bottom: 0;
    }

    @mixin tab-md {
      grid-column: 1/-1;
      padding-bottom: 20px;
    }
  }

  &__item {
    background: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(20px, 15px);
    border-radius: ac(19px, 15px);

    &.with-decor {
      position: relative;
      &:before {
        content: "";
        background-image: url("../images/decor-statistics.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: ac(88px, 58.66px);
        height: ac(84px, 56px);
        position: absolute;
        top: ac(20px, 15px);
        right: ac(47px, 20px);
      }
    }
  }

  &__count {
    font-size: ac(60px, 30px);
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 8px;
  }

  &__item-text {
    width: 66.12%;
    margin-left: auto;

    p {
      font-size: ac(20px, 16px);
      font-weight: 400;
      letter-spacing: 0;
      line-height: 145.5%;
      padding-bottom: 0;
    }

    @mixin tab-md {
      width: 80%;
    }

    @mixin mob {
      width: 100%;
    }
  }
}

.btns-row {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  align-items: flex-start;
  .btn:not(:last-child) {
    margin-right: 16px;
  }
}

.sec-about {
  padding-top: 30px;
  /*@mixin media 750 {
    &.hero-new {
      padding-top: 200px;
    }
  }*/

  @mixin media 650 {
    &.hero-new {
      padding-top: 30px;
    }
  }

  @mixin media 400 {
    &.hero-new {
      padding-top: 20px;
    }
  }

  .cont {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: ac(976px, 676px);
      height: ac(976px, 676px);
      background: radial-gradient(circle, rgba(209,46,37,0.7) 0%, rgba(5,36,83,0) 70%);
      border-radius: 100%;
      top: -160px;
      right: -50%;
      user-select: none;
      pointer-events: none;
    }
    &::after {
      position: absolute;
      content: '';
      width: ac(1100px, 642px);
      height: ac(1100px, 642px);
      background: radial-gradient(circle, rgba(41,86,226,.7) 0%, rgba(41,86,226,0) 60%);
      border-radius: 100%;
      bottom: -145px;
      left: -50%;
      z-index: 10;
      user-select: none;
      pointer-events: none;
    }
  }

  &__top {
    max-width: 670px;
    position: relative;
    z-index: 12;

    .bl {
      &.specialism-view {
        max-width: 940px !important;
        padding-left: ac(30px, 16px);
      }
    }

    h3 {
      letter-spacing: -0.1px;

      &.specialism-view {
        font-size: ac(46px, 21px);
        font-weight: 600;
        line-height: 133%;
      }
    }

    .bl:before {
      top: 4px;
      height: calc(100% - 15px);
    }
  }

  &__top__left {
    &.specialism-var {

      min-height: ac(280px, 240px);

      @mixin media 800 {

        min-height: auto;
      }
    }
  }

  &__wrapp {
    display: flex;
    margin-top: 50px;
    position: relative;
    z-index: 13;
  }

  &__img {
    min-width: 448px;
    width: 448px;
    height: 432px;
    margin-left: ac(-220px, -100px);
    border-radius: 100%;
    overflow: hidden;
    position: relative;

    &.important-image-styles {
      position: relative !important;
      float: right !important;
      transform: translateX(ac(40px, 24px)) !important;
      margin-bottom: ac(40px, 24px) !important;
      margin-top: ac(-100px, 0px) !important;
    }

    @media only screen and (max-width: 1200px) {
      min-width: 348px;
      width: 348px;
      height: 337px;
    }


    @media only screen and (max-width: 950px) {
      min-width: 270px;
      width: 270px;
      height: 262px;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      transform: rotate(47deg) scale(1.03);
    }
  }

  &__content {
    width: 100%;
    max-width: 820px;
    padding: 7px 0 0 76px;

    .btn {
      width: ac(160px, 110px);
    }

    &.specialism-view {
      p {
        font-size: ac(30px, 16px);
        letter-spacing: 0.3px;
        line-height: 133.33%;
        padding-bottom: ac(30px, 10px);

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  &.no-subtitle {
    .sec-about__top__left.specialism-var {
      min-height: auto;
    }

    .sec-about__content {
      margin-left: 0 !important;
      padding-left: 30px !important;
      /*max-width: 70% !important;

      @mixin media 1340 {
        max-width: 60% !important;
      }

      @mixin media 1200 {
        max-width: 50% !important;
      }*/

      @mixin media 800 {
        max-width: 100% !important;
        padding-left: 16px !important;
      }
    }

  }
}

.specialisms {
  position: relative;
  z-index: 4;

  &.microsite {
    padding-top: ac(120px, 50px);
    padding-bottom: ac(120px, 40px);
  }

  .specialisms-arrows {
    display: none;
    justify-content: center;
    margin-top: 27px;

    .btn-arrow {
      margin: 0 7px;
      border-color: var(--main);

      svg {
        path, rect {
          fill: var(--main);
        }
      }

      &.prev {
        transform: rotate(180deg);
      }
    }

    @mixin mob-lg {
      margin-bottom: 40px;
    }
  }

  .cont {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      width: ac(976px, 676px);
      height: ac(976px, 676px);
      background: radial-gradient(circle, rgba(209,46,37,0.7) 0%, rgba(5,36,83,0) 70%);
      border-radius: 100%;
      top: -566px;
      left: -50%;
      z-index: -1;
      user-select: none;
      pointer-events: none;
    }
    &::after {
      position: absolute;
      content: '';
      width: ac(1100px, 642px);
      height: ac(1100px, 642px);
      background: radial-gradient(circle, rgba(41,86,226,.7) 0%, rgba(41,86,226,0) 60%);
      border-radius: 100%;
      top: -545px;
      right: -50%;
      user-select: none;
      pointer-events: none;
    }
  }

  &__grid {
    margin-top: 60px;
    position: relative;
    z-index: 5;

    &.with-home-decor {
      &::before {
        position: absolute;
        content: '';
        width: ac(976px, 676px);
        height: ac(976px, 676px);
        background: radial-gradient(circle, rgba(209,46,37,0.7) 0%, rgba(5,36,83,0) 70%);
        border-radius: 100%;
        z-index: -1;
        user-select: none;
        pointer-events: none;
        bottom: 0;
        right: 0;
        transform: translateX(50%) translateY(60%);
      }
      &::after {
        position: absolute;
        content: '';
        width: ac(1100px, 642px);
        height: ac(1100px, 642px);
        background: radial-gradient(circle, rgba(41,86,226,.7) 0%, rgba(41,86,226,0) 60%);
        border-radius: 100%;
        user-select: none;
        pointer-events: none;
        bottom: 0;
        left: 0;
        transform: translateX(-60%) translateY(50%);
        z-index: -1;
      }
    }

    .swiper-wraper {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-gap: 100px ac(60px, 40px);

      @mixin media 1140 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    &.with-centered {
      @mixin min-media 652 {
        width: calc(100% + ac(20px, 10px));
        margin-left: ac(-10px, -5px);
        .swiper-wraper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: stretch;
          grid-gap: 0;

          .os-card {
            width: 21%;
            margin: 0 ac(20px, 10px) ac(80px, 40px);

            @mixin media 1100 {
              width: 30%;
            }
          }
        }
      }
    }
  }
}

.job-page-form {
  @media screen and (max-width: 901px) {
    .col-span-2 {
      grid-column: span 1 !important;
    }
  }
}

.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}

.os-card {
  display: flex;
  flex-direction: column;
  &__icon {

    svg, img {
      max-width: ac(106px, 83px);
      height: auto;
      max-height: 106px;
      transition: transform .2s ease;
    }
    @media screen and (min-width: 650px) {
      &:hover {
        svg, img {
          transform: scale(1.1);
        }
      }
    }
  }

  h3 {
    font-size: ac(25px, 18px);
    font-weight: 500;
    margin-top: 18px;
    margin-bottom: 11px;
    letter-spacing: -0.5px;
  }

  &:hover {
    text-decoration: none !important;
  }

  .btn-arrow {
    margin-top: auto;
  }

  object {
    flex: 1;
    display: flex;
    align-items: flex-end;
  }
}

.featured-jobs {
  position: relative;
  z-index: 3;
  padding-bottom: ac(150px, 90px);
  margin-top: 88px;
  padding-top: ac(207px, 100px);
  background-image: url('../images/hero-bg.jpg');
  background-size: 100% 100%;

  .wave {
    width: 100%;
    height: auto;
    position: absolute;
    top: -16.8%;
    left: 0;
  }

  .wave-mob {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .cont {
    position: relative;
    max-width: 1350px;
    width: perc(1350);

    &::after {
      position: absolute;
      content: '';
      width: ac(1100px, 642px);
      height: ac(1100px, 642px);
      background: radial-gradient(circle, rgba(41,86,226,.7) 0%, rgba(41,86,226,0) 60%);
      border-radius: 100%;
      top: -688px;
      right: -50%;
      user-select: none;
      pointer-events: none;

      @mixin mob-lg {
        display: none;
      }
    }
  }
  &__wrapp {
    display: flex;
    /* align-items: center; */
    align-items: stretch;
  }

  &__left {
    min-width: 450px;
    width: 450px;
    margin-right: ac(45px, 25px);
    padding-left: 76px;
    margin-top: 0 !important;
    position: relative;

    &-decor {
      @mixin aspect-ratio 272, 259;
      position: absolute;
      left: 13px;
      top: ac(316px, 200px);
      width: ac(272px, 150px);

      img {
        object-fit: contain;
      }
    }

    .bl {
      margin-top: 36px;
      padding-left: ac(60px, 18px);
      padding-bottom: 70px;
      /* min-height: 215px; */
      flex: 1;
      
      /* @media only screen and (max-width: 1922px) {
        min-height: 218px;
      }

      @media only screen and (max-width: 1395px) {
        min-height: 212px;
      }

      @media only screen and (max-width: 1366px) {
        min-height: 210px;
      }

      @media only screen and (max-width: 1250px) {
        min-height: 230px;
      } */

      @mixin mob-lg {
        &.microsite-var {
          padding-top: 10px;
          padding-bottom: 24px;
        }
      }

      .btn {
        width: ac(170px, 120px);
        
      }
      
      .btns-row {
        transform: translateY(10px);
        position: absolute;
        bottom: 0;
        @media only screen and (max-width: 1200px) {
          transform: translateY(15px);
        }

        @mixin mob-lg {
          &.microsite-var {
            position: relative !important;
            transform: none !important;
          }
        }
      }

      .btn-arrow {
        width: 55px;
        height: 55px;
        position: relative;
        z-index: 100;

        &.prev {
          transform: rotate(180deg);
        }

        svg {
          width: 17.42px;
          height: 10px;
        }
      }
    }
  }

  .swiper-creative .swiper-slide {
    overflow: visible;
    height: auto;
  }

  .job-card {
    height: 100%;
    object {
      margin-left: -15px;
    }
    .btn {
      margin: 15px;
    }
  }

  &__slider {
    /* 815 */
    max-width: 395px;
    width: 100%;
    overflow: visible;
    margin-left: 0;

    &.microsite-var {
      @mixin media 451 {
        .swiper-slide {
          &:not(.swiper-slide-active) {
            pointer-events: none;
          }
        }
      }
    }
  }

  .gradient-full {
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, color-mod(var(--bg) a(0%)) 0%, var(--bg) 100%);
    z-index: 1;
    pointer-events: none;

    &::before {
      position: absolute;
      content: '';
      width: ac(1096px, 676px);
      height: ac(1096px, 676px);
      background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(209, 46, 37, 0.70) 0%, rgba(5, 36, 83, 0.00) 70.00%);
      border-radius: 100%;
      bottom: 0;
      transform: translateY(50%) translateX(40%);
      right: 0;
      z-index: 1;
      user-select: none;
      pointer-events: none;
    }
    &::after {
      position: absolute;
      content: '';
      width: ac(816px, 542px);
      height: ac(816px, 542px);
      background: radial-gradient(circle, rgba(41,86,226,.7) 0%, rgba(41,86,226,0) 60%);
      border-radius: 100%;
      bottom: 0;
      transform: translateY(50%) translateX(-50%);
      left: 0;
      user-select: none;
      pointer-events: none;
      z-index: 2;
    }
  }
}

.job-card {
  max-width: 394px;
  width: 100%;
  background-color: var(--primary);
  border-radius: 28px;
  padding-top: 54px;
  padding-left: 64px;
  padding-right: 64px;
  display: flex;
  flex-direction: column;
  border: 3px solid var(--primary);
  transition: background-color .2s ease;

  @media only screen and (min-width: 600px) {
    &:hover {
      /* background-color: var(--bg); */
      background-color: transparent;
    }
  }


  object {
    flex: 1;
    display: flex;
    align-items: flex-end;
    margin-top: -6px;
    transform: translateY(42px);
  }

  h3 {
    font-size: ac(32px, 21px);
    font-weight: 500;
    line-height: 145%;
  }

  &__row {
    margin-top: 27px;
    margin-bottom: 30px;
    p {
      padding-bottom: 0;
    }
  }

  .btn {
    display: inline-flex;
    width: ac(175px, 130px);
  }

  &.fade-hover {
    position: relative;
    /*h3 {
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 45px;
      padding-left: 64px;
      padding-right: 64px;
      transition: opacity .3s ease;
    }*/

    .job-hovered-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: ac(45px, 25px);
      padding-left: ac(64px, 25px);
      padding-right: ac(64px, 25px);
      padding-bottom: ac(64px, 60px);

      p{
        margin-top: auto;
        font-weight: 300;
        padding-bottom: 0;
      }

      h4 {
        font-weight: 300;
        margin-bottom: 0;
      }
    }

    .job-card__row {
      opacity: 0;
      margin-top: 0;
      margin-bottom: 27px;
      transition: opacity .3s ease;
    }

    &:hover {
      .job-hovered-content {
        opacity: 0;
      }
      .job-card__row {
        opacity: 1;
      }
    }
  }
}

.news {
  padding-top: 165px;
  position: relative;
  z-index: 4;

  &.microsite {
    padding-top: ac(135px, 90px);
  }

  .cont {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: ac(840px, 442px);
      height: ac(840px, 442px);
      background: radial-gradient(circle, rgba(41,86,226,.7) 0%, rgba(41,86,226,0) 60%);
      border-radius: 100%;
      top: 0;
      left: -10%;
      z-index: -13;
      user-select: none;
      pointer-events: none;
    }
  }

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 57%;
    background-color: var(--primary);
    top: 0;
    left: 0;
  }

  &__top {
    display: flex;
    justify-content: space-between;

    &.bl {
      align-items: flex-start;
      &::before {
        top: 15px;
        height: calc(100% - 48px);
      }
    }

    h2 {
      margin-left: 10px;
      max-width: 720px;
    }

    .btn {
      width: ac(165px, 107px);
      height: 55px;
    }

    .btn-arrow {
      width: 55px;
      height: 55px;

      &.prev {
        transform: rotate(180deg);
      }

      svg {
        /* width: 17.42px;
        height: 10px; */
      }
    }
  }

  &__slider {
    margin-top: 20px;

    iframe {
      width: 100%;
      height: ac(205px, 140px);

      @mixin media 849 {
        height: 22.97vw;
      }

      @mixin media 449 {
        height: 50.10vw;
      }
    }
  }
}


.news-card {
  max-width: 360px;
  width: 100%;

  &__img {
    width: 100%;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 25px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    z-index: 5;

    &:hover {
      img {
        transform: scale(1.05);
      }
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 72%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      object-fit: cover;
      transition: transform .25s ease;
      z-index: 3;
    }
  }

  h3 {
    font-size: ac(32px, 21px);
    font-weight: 500;
    margin-bottom: 12px;
  }
}

.gallery {
  padding-top: 75px;
  position: relative;
  z-index: 5;
  min-height: 2125px;

  &.home-page {
    z-index: 988;
  }
  
  @mixin media 1415 {
    min-height: 3050px;
  }
  @mixin media 1200 {
    min-height: 2780px;
  }
  @mixin media 1100 {
    min-height: 2660px;
  }
  @mixin media 1060 {
    min-height: 4370px;
  }
  @mixin media 960 {
    min-height: 4170px;
  }
  @mixin media 850 {
    min-height: 3900px;
  }
  @mixin media 768 {
    min-height: 3720px;
  }
  @mixin media 706 {
    min-height: 9900px;
  }
  @mixin media 600 {
    min-height: 8960px;
  }

  .cont {
    max-width: 1220px;
    width: perc(1220);
    position: relative;


  }
  &__grid {
    display: flex;
  }

  &__img {
    margin-bottom: 21px;
    margin-right: 11px;
    margin-left: 11px;

    img {
      width: 100%;
      border-radius: 28px;
    }

    &-width {
      width: calc(25% - 22px);
    }
  }
}

.talk {
  padding-top: ac(223px, 82px);
  padding-bottom: ac(126px, 82px);
  background-image: url('../images/footer.jpg');
  background-size: 100% 100%;
  margin-top: -106px;
  position: relative;

  #footer_form_anchor {
    position: absolute;
    top: -200px;
    pointer-events: none;
  }

  &__err {
    display: none;
  }

  .cont {
    z-index: 99;
  }

  &__slider {
    padding-bottom: 3px;
    .swiper-slide:not(:last-child) {
      height: 36px;
    }
  }

  .input-border-b {
    font-size: ac(18px, 16px);
    color: #fff;
    padding-left: 0;
    line-height: 28px;
    width: 100%;
    background-color: transparent !important;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    resize: none;

    &::placeholder {
      font-size: ac(18px, 16px);
      color: #fff;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
      }
  }

  .select2-container {
    width: 100% !important;
  }

  .cont {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: ac(776px, 676px);
      height: ac(776px, 676px);
      background: radial-gradient(circle, rgba(209,46,37,0.7) 0%, rgba(5,36,83,0) 70%);
      border-radius: 100%;
      top: -1000px;
      left: -50%;
      user-select: none;
      pointer-events: none;
    }
  }

  .wave {
    position: absolute;
    top: -159px;
    width: 100%;
    height: auto;
  }

  .wave-mob {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &__top {
    max-width: 415px;
    margin-bottom: 65px;

    h1, h2 {
      font-size: ac(66px, 46px);
      font-weight: 600;

      line-height: 112.00%;
    }
  }

  &__wrapp {
    padding-top: 27px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .bl {
    padding-left: ac(60px, 18px);

    &::before {
      top: 14px;
      height: calc(100% - 31px);
    }
  }

  &__left {
    /* max-width: 480px; */
    width: 40%;
  }

  &__right {
    padding-top: 17px;
    width: 50%;

    h3 {
      font-weight: 300;
      margin-bottom: 0px;

      mark {
        color: var(--white);
        position: relative;

        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          background-color: var(--white);
          left: 0;
          bottom: -2px;
        }
      }
    }

    input.mark {
      max-width: 240px;
      width: 100%;
      background-color: transparent;
      color: var(--main);
      font-size: ac(32px, 24px);

      &::placeholder {
        color: var(--main);
        font-size: ac(32px, 24px);
      }

      &:focus ~ label.auto-type, &:focus ~ label.auto-type span{
        display: none !important;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active{
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
      }

      &:-webkit-autofill{
        -webkit-text-fill-color: var(--main) !important;
      }
    }
    .input-talk {
      position: relative;
      width: 65%;
      display: flex;
      align-items: center;
      transform: translateY(-2px);
    }
    label {
      max-width: 310px;
      width: 100%;
      left: 0;
      /* top: 50%;
      transform: translateY(-50%);
      position: absolute; */
      color: var(--main);
      font-size: ac(32px, 24px);
      display: flex;
      align-items: center;

      &.cv-label {
        cursor: pointer;

        p {
          transition: color .3s ease;
        }
        &:hover {
          p {
            color: var(--main);
          }
        }

      }
    }
    span.mark {
      color: var(--main);
      font-size: ac(32px, 24px);
      position: relative;
      padding-right: ac(29px, 17px);

      &::before {
        position: absolute;
        content: '';
        width: 1px;
        height: 100%;
        background-color: var(--white);
        top: 0;
        right: 0;
      }
    }
  }

  &__btn {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    .btn {
      display: inline-flex;
    }

    &.first {
      justify-content: space-between;
      align-items: center;

      .btn {
        margin-left: auto;
      }
    }
  }
}

.talk.home-page {
  margin-top: -25px;
}

.gm-style .gm-style-iw-c {
  button.gm-ui-hover-effect {
    display: none !important;
  }
}

.gm-style .gm-style-iw-d {
  img {
    width: ac(96px, 45px);
    height: ac(96px, 45px);
    display: block;
    border-radius: 6px;
    float: left;
    margin-right: ac(26px, 16px);
  }

  div div {
    h4 {
      font-size: ac(24px, 18px);
      color: #052453;
      font-weight: 700;
      display: flex;
      
      &:nth-child(3) {
        font-size: ac(24px, 18px);
        color: #052453;
        font-weight: 500;
        position: relative;
        padding-left: 30px;

        &::before {
          position: absolute;
          content: '';
          top: 2px;
          left: 0;
          width: 24px;
          height: 24px;
          background-image: url('../images/map.svg');
          background-position: center;
          background-size: 100% 100%;
        }
      }
    }
  }

}

.first-sec.jobs-page.microsite {
  display: none;
  margin-bottom: -150px;
  padding-bottom: 0px;
  z-index: 11;
  .wave, .first-sec__overlay {
    display: none;
  }
}

@media only screen and (max-width: 1700px) {
  .talk.home-page .wave {
    top: -90px;
  }
}

@media only screen and (max-width: 1550px) {
  .featured-jobs .wave {
      top: -12%;
  }
}

@media only screen and (max-width: 990px) {
  .featured-jobs .wave {
      top: -9.5% !important;
  }
}

@media only screen and (max-width: 1250px) {
  .featured-jobs {
    &__slider {
      /* max-width: 700px; */
    }
    &__left .bl .btns-row {
      transform: translateY(27px);
    }
    .wave {
      top: -12.8%;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .featured-jobs__left .bl .btns-row {
    transform: translateY(20px);
  }
}

@media only screen and (max-width: 1175px) {
  .news__top h2 {
    max-width: 600px;
  }
}

@media only screen and (max-width: 1100px) {
  .news__top .btns-row {
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 1040px) {
  .talk.home-page {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 1024px) {
  .specialisms {

    &:not(.about-us) {
      margin-top: 80px !important;
    }

    .cont {
      &::before {
        top: -330px;
      }
      &::after {
        top: -349px;
        z-index: -1;
      }
    }
    &__grid .swiper-wraper {
      grid-gap: 70px 40px;
    }
  }

  .featured-jobs__left {
    padding-left: 55px;
    min-width: 375px;
    width: 375px;
  }

  .news__top {
    .btn {
      height: 43px;
    }
    .btn-arrow {
      width: 43px;
      height: 43px;
    }
    &.bl:before {
      height: calc(100% - 30px);
    }
  }
  .talk {
    margin-top: -60px;
    .wave {
      top: -80px;
    }
  }
}

@media only screen and (max-width: 901px) {
  .job-card {
    padding-left: 45px;
    padding-right: 45px;
  }
  .featured-jobs__slider {
    /* max-width: 634px; */
  }
  .featured-jobs__left {
    padding-left: 20px;
    min-width: 290px;
    width: 290px;

    .bl .btn-arrow {
      width: 41px;
      height: 41px;

      svg {
        height: 7px;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .hero {
    &__content {
      width: 455px;
    }
    &__img {
      right: -140px;
    }
  }

  .sec-about .cont:before {
    right: -63%;
  }

  .specialisms__grid .swiper-wraper {
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
}

@media only screen and (max-width: 800px) {
  .talk.home-page .wave {
    top: -85px;
  }
}

@media only screen and (max-width: 768px) {
  .featured-jobs {
    .wave {
      top: -5.8% !important;
    }
    &__wrapp {
      flex-direction: column;
      align-items: flex-start !important;
      margin-top: 40px;
    }
    &__left {
      margin-bottom: 40px;
    }

    &__slider {
      /* max-width: 100%; */
      /* clip-path: inset(0px -100px -60px -100px); */
    }
  }
  .gallery__img-width {
    width: calc(50% - 22px);
  }

  .talk {
    padding-top: 120px;
    .wave {
      top: -50px;
    }
  }

  .talk.home-page {
    margin-top: 30px;
    .wave {
      top: -50px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .hero {
    &__img {
      top: unset;
      bottom: -80%;
    }
  }

  section.sec-about.join-the-team .sec-about__content {
    max-width: 100% !important;
  }
  section.sec-about.about-us .sec-about__top__left {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 650px) {
  .sec-about {
    padding-top: 80px;
    &__img {
      display: none;
    }
    &__content {
      padding-left: 0;
    }
  }
  .specialisms {
    .cont:before {
      display: none;
    }

    &__grid .swiper-wraper {
      grid-template-columns: 1fr;
    }
  }

  .specialisms .os-card {
    display: flex;
    padding-right: 10px;
  }

  .specialisms-arrows {
    display: flex !important;
    margin-bottom: 40px;

  }

  .talk__right {
    width: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    background-position: -400px center;
    background-size: 1440px 100%;
    .btns-row {
      flex-direction: column;

      .btn:first-child {
        margin-bottom: 11px;
      }
    }
  }

  .talk__wrapp {
    flex-direction: column;
  }
  .talk__right {
    width: 100%;
  }

  .job-card {
    padding-left: 30px;
    padding-right: 30px;
    object {
      transform: translateY(30px);
    }
  }
}

@media only screen and (max-width: 550px) {
  .featured-jobs .wave {
    top: -1.8% !important;
  }
  .job-card {
    max-width: 100%;
  }
  .hero:before {
    filter: blur(0);
  }
}

@media only screen and (max-width: 500px) {
  .talk .cont:before {
    display: none;
  }
  .talk__left {
    width: 100%;
  }
  .featured-jobs {
    padding-bottom: 110px;
  }
  .news {
    padding-top: 45px;
    &::before {
      height: 45%;
    }
    &__top {
      flex-direction: column;
    }

    &__top {
      &.bl:before {
        top: 4px;
        height: calc(100% - 23px);
      }
      h2 {
        margin-left: 0;
      }

      .btns-row {
        /*margin-top: -4px !important;*/

        .btn:not(:last-child) {
          margin-right: 13px !important;
        }
      }
      .btn-arrow {
        width: 38px;
        height: 38px;
      }
    }

    &__slider {
      margin-top: 49px;
    }
  }

  .news-card {
    max-width: 100%;

    &__img {
      margin-bottom: 13px;
      &::before {
        padding-bottom: 57%;
      }
    }
  }

  .gallery {
    display: none;
  }

  .talk {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 430px) {
  .featured-jobs {
    padding-top: 50px;
    margin-top: 40px;
  }
  .talk {
    background-size: 500% 100%;
    background-position: -70% center;
    .cont:before {
      left: -90%;
      top: -820px;
    }
    .flex {
      flex-direction: column;
    }
    .input-talk {
      margin-left: 0 !important;
      width: 100%;
    }
    .wave {
      display: none;
    }
    .wave-mob {
      opacity: 1;
      visibility: visible;
      width: 101%;
      height: auto;
      top: -115px;
      left: -1px;
    }

    padding-top: 126px;

    &__wrapp {
      padding-top: 14px;
    }

    .bl:before {
      top: 0;
      height: 100%;
    }

    &__right {
      padding-top: 20px;
      padding-right: 14px;

      h3 {
        margin-bottom: 4px;
        line-height: 213%;
      }

      span {
        padding-right: 8px;

        &::before {
          height: 77%;
          top: 5px;
        }
      }
    }

    &__btn {
      margin-top: 14px;
    }
  }
}

@media only screen and (max-width: 410px) {
  .hero {
    .wave {
      display: none;
    }
    .wave-mob {
      opacity: 1;
      visibility: visible;
      bottom: -25%;
      width: 100%;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .services__wrapp {
    padding-top: 150px !important;
  }

  .sec-cards {
    padding-top: 110px !important;
  }

  .talk.join-the-team {
    margin-top: 30px !important;
  }

  .benefits-slider.join-the-team {
    padding-top: 100px !important;
    padding-bottom: 60px !important;

    .wave {
      top: -35px !important;
    }
  }

  .our-services .service-block:last-child {
    margin-bottom: 0;
  }

  .solution-block {
    margin-bottom: 50px !important;
    &__bottom {
      padding-bottom: 25px !important;
    }

    .benefits-slider {
      margin-top: 60px;
    }
  }

  .talk.jobs-page {
    margin-top: 0 !important;
    padding-top: 90px !important;
    .wave-mob {
      top: -160px;
    }
  }

  .talk.specialisms-page {
    margin-top: 50px !important;
  }

  .featured-jobs.join-the-team {
    padding-top: 20px !important;
  }

  .featured-jobs.microsite {
    padding-top: 35px;
    margin-top: -50px;
    .wave-mob {
      top: -11%;
    }
  }

  .job-card {
    padding-top: 43px;
    padding-left: 30px;
    padding-right: 30px;

    h3 {
      line-height: 163%;
    }

    &__row {
      margin-bottom: 8px;
      margin-top: 14px;
    }
  }

  .featured-jobs {
    background-size: 500% 100%;
    background-position: -89% center;
    margin-top: -35px;
    padding-top: 72px;

    &.microsite-var {
      clip-path: inset(-50px);
    }

    .wave {
      display: none;
    }

    .wave-mob {
      opacity: 1;
      visibility: visible;
      top: -3%;
      width: 101%;
      height: auto;
    }

    .swiper-slide-next {
      opacity: 0 !important;
    }

    &__left {
      min-width: 100%;
      width: 100%;
      padding-left: 26px;
      margin-bottom: 50px;

      .bl {
        margin-top: -8px;

        .btns-row .btn:not(:last-child) {
          margin-right: 13px !important;
        }

        p {
          line-height: 184%;
        }
        .btn-arrow {
          width: 38px;
          height: 38px;
        }
      }
    }

    &__slider {
      padding-left: 26px;
      padding-right: 26px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .talk {
    .cont {
      max-width: 1155px;
      width: perc(1250);
      /* padding-left: 12px; */
    }
  }

  .hero {
    padding-bottom: 100px;

    h1 {
      max-width: 290px;
    }

    &__block {
      margin-top: 21px;
    }

    .btns-row {
      margin-top: 16px;
    }

    &__img {
      bottom: -67%;
      z-index: 20;
    }
  }
  .sec-about {
    padding-top: 295px;

    .cont:before {
      right: -142%;
      top: -24px;
    }

    .cont::after {
      left: -85%;
      bottom: unset;
      top: -300px;
    }

    .bl {
      margin-top: -7px !important;

      h3 {
        line-height: 166%;
      }
    }

    &__wrapp {
      margin-top: 19px;

      p {
        line-height: 185%;
        letter-spacing: 0.2px;
      }

      .btns-row {
        margin-top: 17px !important;
        .btn:not(:last-child) {
          margin-right: 13px !important;
        }
      }
    }
  }

  .specialisms {

    &:not(.about-us) {
      margin-top: 161px!important;
    }

    .cont:after {
      right: -100%;
    }
    &__grid {
      margin-top: 42px;
    }

    .os-card {
      margin-right: 13px;

      h3 {
        word-wrap: break-word;
        font-size: 16px;
      }
    }

    .specialisms-arrows {
      margin-top: 16px;
    }
  }

  .talk {
    margin-top: 0px;
    padding-top: 90px;
  }

  .specialisms .specialisms-arrows {
    margin-top: 40px;
  }

  .specialisms {

    &:not(.about-us) {
      margin-top: 80px !important;
    }
  }
}

 /* TALENT */

.case-pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0, .5);
  z-index: 10;

  .case-count {
    background: #fff;
    border-radius: 4px;
    padding: 2rem 1.5rem;

    h4.gen-title {
      color: #000;
    }
  }

  input {
    margin-bottom: 20px;
    font-size: ac(18px, 16px);
    color: #000;
    padding-left: 0;
    line-height: 28px;
    width: 100%;
    background-color: transparent !important;
    border-bottom: 1px solid #000;
    padding-bottom: 5px;

    &::placeholder {
      font-size: ac(18px, 16px);
      color: #000;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
      }
  }

  section {
    margin-bottom: 20px;
  }
}

.head-block {
  /* padding-top: 170px; */
}

.develop-sec, .candidate-sec {
  .btn {
    display: inline-flex;
    margin-bottom: 20px;
  }
}

.consultant-sec {
  h3.title-small {
    color: #fff;
  }
  .input-border-b {
    margin-bottom: 20px;
    font-size: ac(18px, 16px);
    color: #fff;
    padding-left: 0;
    line-height: 28px;
    width: 100%;
    background-color: transparent !important;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;

    &::placeholder {
      font-size: ac(18px, 16px);
      color: #fff;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #fff !important;
      }
  }
}


.open-header-sec {
  padding-top: 160px;
  padding-bottom: 40px;
}

.employer-sec-page {
  padding-top: 50px;
  .img-sec {
    display: block;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    object-fit: cover;
    text-align: center;
    margin-bottom: 20px;
  }
}

.testi-sec__content {
  width: 100%;
  position: relative;
  z-index: 1;

  h1 {
    font-size: ac(66px, 46px);
    font-weight: 600;
    line-height: 120%;
  }

  a:not(.btn):not(.job-card) {
    color: var(--secondary) !important;
    font-size: inherit;
  }
}

.testi-sec__slider {
  padding-top: 80px;
  .swiper-slide {
    border-radius: 28px;
    background-color: #E40512;
    padding: 2rem 2.4rem;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      background-image: url("../images/key/testi-bg.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-55%);
      width: ac(1952px, 976px);
      height: ac(1468px, 734px);
    }
  }

  .testi-btns {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 5px;

    button {
      margin: 0 5px;

      &:first-child {
        transform: rotate(180deg);
      }
    }
  }
}

.modal-content {
  margin-bottom: 60px;
  .vimeo {
    iframe {
      margin: 0 auto;
      width: 80%;
      height: 600px;

      @media screen and (max-width: 1030px) {
        height: 450px;
      }

      @media screen and (max-width: 768px) {
        height: 380px;
        width: 100%;
      }

      @media screen and (max-width: 450px) {
        height: 300px;
      }
    }
  }
}

.gen-title {
  font-size: ac(66px, 50px);
  font-weight: 600;

  line-height: 112%;

  margin-bottom: ac(18px, 10px);

  @media only screen and (max-width: 400px) {
    line-height: 120%;
  }
}

.hc-info {
  margin-top: ac(48px, 32px);
}

.develop-sec, .consultant-sec {
  background: transparent;
}

.develop-cont > ul > li .box-sec {
  background: var(--primary);
  padding: 40px 35px;

  .box-bottom-line {
    position: static;
  }

  .btn:not(.second):hover {
    background-color: #0f213d;
    box-shadow: inset 0px -60px 0px 0px #0f213d;
    color: #fff;
  }
}

.sec-flex {
  a:not(.btn) {
    color: var(--main);
  }

  .btn:not(.second):hover {
    background-color: #0f213d;
    box-shadow: inset 0px -60px 0px 0px #0f213d;
    color: #fff;
  }

  .left-sec {
    background: var(--primary);
    border-radius: 28px;
    box-shadow: none;
  }

  .right-sec {
    border-radius: 28px;
    background: var(--primary);

    .btn.second {
      margin-bottom: 10px;
    }
  }
}

.consultant-sec .input-border-b {
  border: 1px solid #fff;
  padding: 10px 17px;
}

.info-table {
  li {
    padding-left: 0 !important;
    &::before {
      display: none;
    }
    &:nth-child(odd) {
      background: #0f213d;
    }
  }
}

.employer-sec-page-more {
  position: relative;
  z-index: 10;
  margin-top: ac(-200px, -40px);

  .employer-cont {
    &__block {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;

      @media screen and (max-width: 850px) {
        flex-direction: column;

        .employer-cont__block__img {
          margin-left: 0;
          margin-top: 30px;
          max-width: 100%;
          img {
            max-width: 100%;
          }
        }
      }

      &__cont {
        order: 1;
        max-width: 400px;
        width: 100%;
      }
      &__img {
        max-width: 600px;
        order: 2;
        margin-left: 30px;
      }
    }
  }
}
@media screen and (max-width: 1360px) {
  .featured-jobs {
    &__wrapp {
      /* align-items: center; */
    }
    &__left .bl {
      /* min-height: unset; */
    }
  }
} 

@media screen and (max-width: 980px) {
  .sec-about.join-the-team .sec-about__content {
    max-width: 450px !important;
    &.max-w-full {
      max-width: 100% !important;
    }
  }
  .sec-about.about-us .sec-about__top__left {
    max-width: 50% !important;
  }
}

@media screen and (max-width: 850px) {
  .employer-sec-page-more .employer-cont__block__cont {
    max-width: 100%; 
    .img-sec {
      max-width: 100%; 
      img {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .quote {
    iframe {
      height: 270px !important;
    }
  } 
}
@media screen and (max-width: 400px) {
  .hero.internal-hero .hero__content {
    width: 100% !important;

    h1 {
      word-wrap: break-word;
    }
  }
} 

@media screen and (max-width: 480px) {
  .quote {
    iframe {
      height: 220px !important;
    }
  } 
}

.quote {
  li {
    color: #fff;
  }
}

.first-sec__content__img {
  max-width: ac(300px, 200px);
  margin: 0 auto 50px;
}

@media screen and (max-width: 500px) {
  
  .first-sec.blog-details {
    .first-sec__bg.deck {
      display: none;
    }
  }
  .first-sec.microsite {
    padding-top: 180px;
    padding-bottom: 180px;
  }
}

.head-cont {
  .hc-info h4 span {
    color: var(--main);
  }
}

.candidate-cont .row {
  display: flex;

  .col-md-6 {
    width: 50%;
    &:not(:last-child) {
      padding-right: 30px;
      @media screen and (max-width: 768px) {
        padding-right: 0;
      }
    }

    .cc-flex .sub-head {
      max-width: 195px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    .col-md-6 {
      width: 100%;
    }
  }
}

.gallery-sec {
  .slick-slide {
    height: 380px !important;

    &::before {
      display: none;
    }

    @media screen and (max-width: 420px) {
      height: 265px !important;
    }
  }
}

.content-scroll {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #0f213d; 
  }
  &::-webkit-scrollbar-thumb {
    background: var(--main); 
  }
}

.news.microsite {
  padding-top: ac(130px, 100px);
  padding-bottom: ac(40px, 20px);
}

.our-location {
  margin-top: ac(100px, 50px);
  position: relative;
  z-index: 10;

  #office-locations {
    background-color: #051733;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 30px 16px 20px;
    font-size: ac(20px, 18px);
    border-radius: 28px 28px 0 0;
  }

  #map {
    border-radius: 28px;
    
    .gm-style {
      border-radius: 28px;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 600px) {
    #office-locations {
      width: 100%;
      padding-bottom: 50px;
      margin-bottom: -30px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .featured-jobs {
    padding-top: 130px;
    .wave {
      top: -15%;
    }

    &.microsite {
      margin-top: 40px;
      padding-bottom: ac(128px, 90px);

      @mixin tab-sm {
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1270px) {
  .featured-jobs {
    .wave {
      top: -13%;
    }
  }
} 

.news-card.video {
  position: relative;

  &::before {
    background-image: url('../images/join-the-team/play-white.svg');
    background-repeat: no-repeat;
    content: "";
    height: 62px;
    transition: .2s ease;
    width: 54px;
    position: absolute;
    top: calc(50% - 10px);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-size: cover;
  }
}

.skill-sec {
  background: transparent;

  .skill-inner {
    background: var(--primary);
    input {
      color: #222;
    }
    select {
      background: #fff;
      border: 1px solid #8b8b8b;
      box-sizing: border-box;
      color: #222;
      display: block;
      font: 18px/130% Lexend Deca,sans-serif;
      height: 43px;
      padding: 0 16px;
      width: 100%;
    }

    .form-advanced {
      display: flex;
      flex-direction: column;
    }
  }

  .btn-wrap {
    padding-top: 20px;
    display: flex;

    .btn {
      margin-bottom: 10px;

      &:hover {
        background-color: #0d2140;
        box-shadow: inset 0px -60px 0px 0px #0d2140;
      }
    }

    a.btn {
      max-width: 180px;
      margin-left: 10px;
      -webkit-appearance: none;
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;

      a.btn {
        margin-left: 0;
        max-width: 100%;
      }
    }
  }

  ul li {
    @media screen and (max-width: 500px) {
      margin-top: 13px;
    }
    h3 {
      color: #fff;
    }
  }

  a.advance-link {
    color: #fff;
    margin-left: 0;
  }
}

.map-cont {
  #map {
    height: 720px;
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
      height: 490px;
    }

    @media screen and (max-width: 600px) {
      height: 280px;
    }
  }
}

.suggests_wrap {
  position: relative;
  z-index: 9999;
}

.suggests_result {
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  min-height: 0;
  max-height: 300px;
  /*margin-top: -10px;*/
  border: 1px solid #E40512 !important;
  border-radius: 0 !important;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 99999;
}

.suggests_result:empty {
  display: none;
}

.suggests_result .pc-item {
  padding: 0 20px;
  line-height: 60px;
  font-size: 20px;
  color: #051733;
}

.suggests_result .pc-item:hover {
  background-color: #051733 !important;
  color: white;
  cursor: pointer;
}

.input-border-b {
  -webkit-appearance: none;
  border-radius: 0;
  transition: .2s ease;

  &:focus {
    border-color: var(--main);
  }
}

.map-location-active {
  font-weight: 700;
}

@media screen and (max-width: 350px) {
  .featured-jobs.microsite .wave-mob {
    top: -8%;
  }
}

.form .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
  transition: transform .3s ease;
  transform: rotate(180deg) translateY(50%);
}

.jobs .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow,
.news-sec .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow{
  transition: transform .3s ease;
  transform: rotate(180deg);
}

.talk .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
  transition: transform .3s ease;
  transform: rotate(180deg) translateY(-3px);
}

.select2-container--open .select2-dropdown--below {
  border: 2px solid var(--main);
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}

.select2-container--default .select2-results>.select2-results__options {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #0f213d; 
  }
  &::-webkit-scrollbar-thumb {
    background: var(--main); 
  }
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--main);
}

.employer-section {
  margin-top: ac(-105px, -40px);
  padding-bottom: ac(70px, 60px);
  position: relative;
  z-index: 99;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    right: ac(100px, 50px);
    transform: translateY(55%);
    width: ac(276px, 138px);
    height: ac(263px, 131.5px);
    opacity: .3;
    background-image: url("../images/decor-statistics.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;

    &::before {
      position: absolute;
      content: '';
      width: ac(976px, 676px);
      height: ac(976px, 676px);
      background: radial-gradient(circle, rgba(209,46,37,0.7) 0%, rgba(5,36,83,0) 70%);
      border-radius: 100%;
      bottom: 0;
      left: -50%;
      z-index: -1;
      user-select: none;
      pointer-events: none;
      transform: translateY(55%) translateX(10%);
    }
    &::after {
      position: absolute;
      content: '';
      width: ac(1100px, 642px);
      height: ac(1100px, 642px);
      background: radial-gradient(circle, rgba(41,86,226,.7) 0%, rgba(41,86,226,0) 60%);
      border-radius: 100%;
      bottom: 0;
      right: -50%;
      user-select: none;
      pointer-events: none;
      z-index: -2;
      transform: translateY(55%) translateX(10%);
    }
  }

  &__title {
    padding-bottom: ac(45px, 30px);
    margin-bottom: 0;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: ac(60px, 30px);
    grid-row-gap: ac(30px, 20px);
    list-style: none;
    width: ac(1260px, 1000px);

    @mixin media 1300 {
      width: 100%;
    }

    @mixin mob-xl {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 0;
  }

  &__list-logo {
    width: ac(106px, 60px);
    height: ac(106px, 60px);
    margin-bottom: ac(30px, 20px);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__list-title {
    padding-bottom: 10px;
    h4 {
      margin-bottom: 0;
    }
  }

  &__list-info {
    max-width: 220px;

    a, span {
      word-break: break-all;
      color: #E40512;
      font: 500 ac(22px, 16px) / 130% var(--font-main) !important;
    }

    a {
      transition: color .25s ease;
      &:hover {
        text-decoration: underline;
        color: var(--secondary);
      }
    }
  }

  &__info {
    padding-top: ac(84px, 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      padding-bottom: ac(40px, 30px);
    }

    @mixin mob-xl {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__photo {
    @mixin aspect-ratio 464, 362;
    border-radius: ac(28px, 18px);
    width: 38.67%;

    /*&:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background-image: url("../images/key/bg.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }*/

    @mixin mob-xl {
      width: 100%;
      max-width: 448px;
      transform: none;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__text {
    width: 57.17%;

    p {
      font-size: ac(21px, 16px);
      /* font-size: ac(30px, 21px); */
      font-weight: 400;
      line-height: 153%;
      padding-bottom: 0;
      letter-spacing: 0;

      &:not(:last-child) {
        padding-bottom: ac(14px, 10px);
      }
    }

    ul, ol {
      li {
        font-size: ac(21px, 16px);
        font-weight: 400;
        line-height: 153%;
        padding-bottom: 0;
        letter-spacing: 0;
      }
    }

    ul {
      li {
        &:before {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    @mixin mob-xl {
      width: 100%;
      padding-top: 30px;
    }
  }
}

.grecaptcha-badge {
  z-index: 99999;
}

.team-card-section {
  padding-bottom: ac(90px, 60px);
  z-index: 1;

  &.not-vacancies {
    padding-top: ac(180px, 120px);
  }

  + .our-location {
    margin-top: 0;
  }

  .team-card-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: opacity .3s ease;
    opacity: 0;

    .swiper-wrapper {

    }

    &__buttons {

      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .btn-arrow {
        margin-top: 10px;

        &.prev {
          transform: scale(-1, 1);
        }

        &.swiper-button-lock {
          display: none;
        }
      }
    }

    .team-card {
      width: 100%;
      padding: ac(50px, 25px);
      border-radius: ac(28px, 15px);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: var(--primary);

      @mixin mob-lg {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &__img {
        @mixin aspect-ratio 240, 250;
        border-radius: ac(14px, 10px);
        width: 21.82%;

        @mixin tab {
          width: 33%;
        }

        @mixin mob-lg {
          margin-bottom: 30px;
          width: 100%;
          max-width: 240px;
          margin-left: 0;
          margin-right: auto;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 74.55%;

        @mixin tab {
          width: 65%;
        }

        @mixin mob-lg {
          width: 100%;
        }
      }

      &__name {
        padding-bottom: 10px;

        h3 {
          margin-bottom: 0;
          font-size: ac(26px, 18px);
          font-weight: 500;
          letter-spacing: 0.3px;
          line-height: 120%;
        }
      }

      &__position {
        padding-bottom: ac(20px, 15px);

        p {
          font-size: ac(21px, 16px);
          font-weight: 500;
          letter-spacing: 0.3px;
          line-height: 120%;
          padding-bottom: 0;
          color: var(--main);
        }
      }

      &__socials {
        padding-bottom: ac(20px, 15px);


        .social.social-sm {
          margin-top: 0;
          flex-direction: row;
          padding-left: 0;

          a {
            margin: 0;
            margin-right: 12px;
            width: ac(38px, 28px);
            height: ac(38px, 28px);

            span {
              font-size: ac(18px, 14px);
            }

            &:hover {
              background: var(--bg);
            }
          }
        }
      }

      &__info-text{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding-left: ac(30px, 16px);

        a {
          font-size: ac(18px, 15px);
          font-weight: 500;
          letter-spacing: 0.3px;
          line-height: 122.22%;
          margin-bottom: 10px;
          color: var(--white);

          &:hover {
            text-decoration: underline;
            color: var(--main);
          }
        }

        p {
          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      &.swiper-slide {
        display: flex;
        flex-wrap: wrap;
        padding: ac(40px, 24px);

        .team-card__img {
          width: 30%;
        }

        .team-card__info {
          width: 66%;
        }

        .team-card__info-text {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}

.microsite-jobs-team-gradient {
  position: relative;
  padding-top: ac(110px, 60px);
  margin-top: ac(-110px, -60px);

  &__decor {
    position: absolute;
    width: 100%;
    top: -1px;
    left: 0;
    height: auto;
    z-index: 1;
    transform: scale(1.02);

    img {
      width: 100%;
      height: auto;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @mixin mob-lg {
        opacity: .9;
      }
    }
  }

  .featured-jobs {
    background: transparent;
    z-index: 10;

  }

  @mixin mob-lg {
    margin-top: 0;
  }
}

.vid1-dimensions {
  height: unset;
  padding-top: 56% !important;
}

.fancybox-container {
  z-index: 999999;
}

.fancybox-stage {
  background-color: #1e1e1ece !important;
  transition: background-color .2s cubic-bezier(.22,.61,.36,1);
}

.fancybox-bg {
  opacity: 0 !important;
  z-index: 99995;
}

.microsite-form {
  .select2-container {
    width: 100% !important;
    grid-column: span 2/span 2!important;
    
    @media screen and (max-width: 901px) {
      grid-column: unset !important;
    }
  }
}

.benefits-slider-micro {
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @mixin mob-lg {
      flex-direction: column;
      align-items: center;

      .benefits-circle {
        display: none;
      }
    }
  }

  &__text {
    margin-left: ac(30px, 15px);

    @mixin mob-lg {
      margin-top: 20px;
      width: 100%;
    }

    & > * {
      &:last-child {
        padding-bottom: 0;
      }
    }

    p, li {
      font-size: ac(21px, 16px);
      letter-spacing: 0.3px;
      line-height: 155%;

      a {
        font: inherit;
      }
    }
  }

  &__logo {
    border: 3px solid var(--primary);
    background: var(--primary);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: ac(21px, 15px);
    border-radius: 8px;
    height: auto;
    transition: background-color .25s ease, transform .25s ease;
    max-width: 300px;
  }

  &__icon {
    min-width: ac(72px, 60px);
    width: ac(72px, 60px);
    height: ac(72px, 60px);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__text-logo {
    display: flex;
    align-items: center;
    margin-left: ac(24px, 12px);

    p {
      font-size: ac(21px, 15px);
      font-weight: 600;
      letter-spacing: 0;
      line-height: 135%;
    }

    & > * {
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.video-before-micro {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: -180px;
    left: -50vw;
    right: -50vw;
    bottom: -60px;
    background-color: var(--primary);
    z-index: -1;
  }
}

.relative {
  position: relative;
}

.brochure-section {
  padding-top: ac(20px, 15px);
  padding-bottom: 0;
  position: relative;
  z-index: 101;

  &__wrap {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    max-width: ac(900px, 650px);
    margin: 0 auto;
    position: relative;

    .brochure-card {
      width: calc(48% - 10px);
      margin: 0 10px 20px;
    }

    @mixin tab-sm {
      max-width: 100%;
    }

    @mixin mob-xl {
      .brochure-card {
        width: 100%;
        margin: 0 0 20px;
      }
    }
  }
}

.brochure-card {
  padding: ac(30px, 24px);
  /*background: var(--primary);*/
  border-radius: ac(28px, 18px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__icon {
    width: ac(74px, 60px);
    height: ac(74px, 60px);
    margin-bottom: ac(30px, 20px);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  &__title {
    margin-bottom: ac(20px, 16px);
  }

  &__text {
    margin-bottom: ac(50px, 28px);
  }

  .btn {
    width: 100%;
    margin-top: auto;
    box-shadow: 0 3px 7px 4px rgba(0,0,0,.24) !important;
  }
}

.gallery-section {
  margin-top: ac(60px, 30px);
  padding-top: ac(100px, 60px);
  padding-bottom: ac(120px, 60px);
  position: relative;
  z-index: 10;
  overflow: hidden;

  @mixin mob-xl {
    overflow: visible;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__top {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;
    flex-wrap: wrap;
    z-index: 1;

    &:before {
      top: ac(-100px, -60px);
      left: -100vw;
      right: -100vw;
      bottom: ac(-180px, -90px);
      position: absolute;
      content: "";
      background: var(--primary);
      z-index: -1;
    }

    .gallery-section__text {
      width: 100%;
      order: 3;
    }

    @mixin media 620 {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .gallery-section__text {
        order: initial;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;

    &:before {
      position: absolute;
      z-index: -1;
      content: "";
      inset: 0;
      border: 2px solid var(--bg);
      border-radius: 5px;
    }

    .btn {
      min-width: ac(206px, 160px);
      border-color: transparent !important;
      box-shadow: none !important;

      &.active {
        background-color: var(--bg) !important;
        border: 2px solid var(--main) !important;
        box-shadow: 0 3px 7px 4px rgba(0, 0, 0, 0.24) !important;

        &:hover {
          background-color: var(--main);
          border: 2px solid var(--main);
        }
      }

      &:not(.active) {
        &:hover {
          background-color: rgba(228, 5, 18, 0.5) !important;
        }
      }

      @mixin mob {
        min-width: 120px;
      }
    }

    @mixin media 620 {
      margin-top: 20px;
    }
  }

  &__title {
    max-width: ac(653px, 550px);
    padding-left: ac(47px, 20px);
    border-left: 1px solid var(--white);

    h2 {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    @mixin tab-sm {
      max-width: calc(100% - 110px);
    }
  }

  &__text {
    padding-top: ac(30px, 20px);
    max-width: ac(653px, 550px);
    padding-left: ac(69px, 30px);

    @mixin tab-sm {
      max-width: 80%;
    }

    @mixin mob-lg {
      max-width: 90%;
    }

    @mixin mob {
      max-width: 100%;
    }
  }

  &__slider-buttons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .btn-arrow.prev {
      transform: scale(-1, 1);
    }

    &:not(.active) {
      display: none;
    }
  }

  &__sliders {
    width: 100%;
    position: relative;
    z-index: 4;
  }

  &__slider {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &.active {
      top: auto;
      left: auto;
      position: relative;
      opacity: 1;
      pointer-events: auto;
      z-index: 2;
    }

    &:not(.active) {
      z-index: -1;
      pointer-events: none;
    }

    @mixin media 690 {
      flex-direction: column;
    }
  }

  .gallery-slider-nav-container {
    width: 30.58%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;

    &__title {
      font-size: ac(30px, 22px);
      margin-top: ac(24px, 0px);
      margin-bottom: ac(24px, 20px);
    }

    @mixin media 690 {
      overflow: visible;
      width: 100%;
      margin-top: 24px;

      .gallery-slider-nav-container__title {
        display: none;
      }
    }
  }

  .gallery-slider-nav {
    width: 100%;
    max-width: 100%;
    max-height: ac(348px, 238px);
    overflow: hidden;
    position: relative;

    &.with-3-items {
      .swiper-slide {
        min-height: ac(94px, 56px);
      }
    }

    .swiper-scrollbar {
      background: linear-gradient(180deg, rgba(41,86,226,1) 0%, rgba(41,86,226,0.1) 100%) !important;

      @mixin min-media 691 {
        width: 2px !important;
        max-height: inherit !important;
      }

      @mixin media 690 {
        bottom: -16px;
        left: 0;
        width: 100%;
        height: 2px !important;
        background: linear-gradient(90deg, rgba(41,86,226,1) 0%, rgba(41,86,226,0.1) 100%) !important;
      }
    }

    .swiper-scrollbar-drag {
      background-color: #E40512 !important;
      border-radius: 0;

      @mixin min-media 691 {
        width: 6px !important;
        left: -1.5px !important;
      }

      @mixin media 690 {
        height: 6px !important;
        top: -2.5px !important;
      }
    }

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 28px;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: calc(6px + ac(30px, 10px));
      cursor: pointer;

      &:hover {
        .gallery-slider-nav__text {
          h4 {
            color: #E40512;
          }
        }
      }

      @mixin media 620 {
        padding-right: 0;
      }
    }

    &__image,
    &__video {
      @mixin aspect-ratio 165, 94;
      width: 50.61%;
      overflow: hidden;
      border-radius: 8px;

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) translateZ(0);
        width: 24px;
        height: 28px;
        z-index: 2;
        background: var(--white);
        clip-path: url("#play-clip-path");
      }

      &.video-type {
        &:after {
          content: "";
        }
      }
    }

    &__video {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      &:after {
        content: "";
      }
    }

    &__text {
      width: 44.48%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h4 {
        transition: color .3s ease;
        font-size: ac(20px, 16px);
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
        color: var(--white);
        @mixin max-line-length 4;
      }
    }

    @mixin media 1441 {

      h4 {
        font-size: ac(18px, 16px);
      }
    }

    @mixin media 1279 {
      h4 {
        font-size: 16px;
      }
    }

    @mixin media 1120 {
      h4 {
        font-size: 16px;
      }
    }

    @mixin media 690 {
      margin-left: 0;
      overflow: visible;
    }
  }

  .gallery-slider {
    overflow: visible;
    width: 66.08%;
    max-width: 66.08%;
    clip-path: inset(0 -48.55% -400px 0);
    margin-left: 0;
    position: relative;

    /*.toggle-slide {
      bottom: 0;
      right: 0;
      border: 2px solid yellow;
      width: 44.44%;
      @mixin aspect-ratio 360, 200;
      transform: translateX(108%);
      z-index: 5;
      position: absolute;
      cursor: pointer;
      opacity: 0;

      @mixin media 1260 {
        width: 42%;
      }

      @mixin media 690 {
        display: none;
      }
    }*/

    &__image {
      width: 100%;
      @mixin aspect-ratio 360, 200;
      border-radius: ac(20px, 12px);
      transition: all .25s ease;

      @mixin media 690 {
        width: 100%;
        @mixin aspect-ratio 810, 488;
      }
    }

    &__video {
      width: 100%;
      @mixin aspect-ratio-plyr 360, 200;
      border-radius: ac(20px, 12px);
      transition: all .25s ease;
      clip-path: inset(0 0 0 0 round ac(20px, 12px));

      @mixin media 690 {
        width: 100%;
        @mixin aspect-ratio 810, 488;
      }
    }

    &__video-container {

      .plyr {
        height: 100%;
        width: 100%;
        cursor: pointer;

        &.plyr--stopped {
          video {
            opacity: 0;
          }
        }
      }

      .plyr-hide-controls {
        .plyr__controls {
          opacity: 0;
        }
      }

      .plyr__poster {
        background-size: cover;
      }

      .plyr__control--overlaid {
        --plyr-control-icon-size: ac(60px, 24px);
        background: transparent !important;
        opacity: 1;

        &:hover {
          color: var(--main) !important;
        }
      }

    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
    }

    &__text {
      position: absolute;
      bottom: ac(-16px, -10px);
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;
      max-width: 100%;
      transform: translateY(100%);
      @mixin min-media 652 {
        @mixin max-line-length-one;
      }

      h4 {
        font-size: ac(32px, 20px);
        margin-bottom: 0;
        @mixin min-media 652 {
          @mixin max-line-length-one;
        }
      }

      @mixin media 690 {
        position: relative;
        transform: none;
        width: 100%;
        margin-top: 16px;
        bottom: auto;

        @mixin max-line-length 3;
        h4 {
          @mixin max-line-length 3;
        }
      }
    }

    .swiper-slide {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;

      .gallery-slider__item {
        margin-top: auto;
        width: 100%;
        transition: all .5s ease;
        pointer-events: none;
/*
        @mixin media 1260 {
          width: 42%;
        }

        @mixin media 690 {
          width: 100%;
        }*/
      }

      &.swiper-slide-next {
        /*.gallery-slider__item {
          margin-top: 0;
        }*/

        /*~ .swiper-slide {
            .gallery-slider__item {
              transform: translateX(-233.4%);

              @mixin media 1260 {
                transform: translateX(-248%);
              }

              @mixin media 1100 {
                transform: translateX(-250%);
              }

              @mixin tab {
                transform: translateX(-252%);
              }

              @mixin tab-md {
                transform: translateX(-253%);
              }

              @mixin media 690 {
                transform: none;
              }
            }
        }*/
      }

      &.swiper-slide-active {
        position: relative;
        z-index: 1;
        cursor: auto;
        .gallery-slider__item {
          margin-top: 0;
          width: 100%;
          pointer-events: auto;
        }

        .gallery-slider__image {
          width: 100%;
          @mixin aspect-ratio 810, 488;
        }

        .gallery-slider__video {
          width: 100%;
          @mixin aspect-ratio-plyr 810, 488;
        }

        .gallery-slider__text {
          @mixin max-line-length 3;
          h4 {
            @mixin max-line-length 3;
          }
        }
      }
    }

    @mixin tab-sm {
      width: 64.50%;
      max-width: 67.50%;
      clip-path: inset(0 -52.55% -400px 0);
    }

    @mixin media 690 {
      width: 100%;
      max-width: 100%;
    }
  }

  + .specialisms {
    margin-top: 0 !important;
    padding-top: ac(120px, 90px);

    .cont {
      &:before, &:after {
        display: none;
      }
    }
  }
}

.gallery-video-slider {
  iframe {
    transition: opacity .3s ease;
  }
  &.fix-iframe {
    @mixin mob-xl {
      iframe {
        opacity: 0 !important;
      }

      .plyr__poster {
        display: block;
        opacity: 1;
      }
    }
  }

}

.new-benefits-section {
  padding-top: ac(90px, 64px);
  padding-bottom: ac(90px, 64px);
  position: relative;
  z-index: 20;

  + .history-section {
    margin-top: ac(-200px, -190px);
  }

  &__wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__container {
    padding-top: 24px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,minmax(0,1fr));
    grid-row-gap: ac(20px, 18px);
    grid-column-gap: ac(20px, 18px);

    @mixin tab {
      grid-template-columns: repeat(3,minmax(0,1fr));
    }

    @mixin mob-lg {
      grid-template-columns: repeat(2,minmax(0,1fr));
    }

    &.with-centered {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      grid-row-gap: 0;
      grid-column-gap: 0;
      width: calc(100% + ac(20px, 10px));
      margin-left: ac(-10px, -5px);

      @mixin tab {
        width: 100%;
        margin-left: 0;
      }

      .new-benefits-section__item {
        width: 23.45%;
        margin: 0 8px 16px;
      }

      @mixin tab {
        .new-benefits-section__item {
          width: 31%;
        }
      }

      @mixin tab-sm {
        .new-benefits-section__item {
          width: 30%;
        }
      }

      @mixin mob-lg {
        .new-benefits-section__item {
          width: 45%;
        }
      }

      @mixin mob-sm {
        .new-benefits-section__item {
          width: 100%;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 19px;
    background: var(--primary) !important;
    padding: 24px;

    @mixin mob {
      padding: 20px;
    }
  }

  &__icon {
    @mixin aspect-ratio 1, 1;
    width: ac(88px, 56px);
    margin-bottom: ac(30px, 20px);
    max-width: 120px;
  }

  &__text {
    text-align: left;
    p {
      font-size: ac(24px, 16px);
    }
  }

}

.testi-sec {
  position: relative;
  z-index: 10;
}