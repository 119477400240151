@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?l8nash');
  src:  url('../fonts/icomoon.eot?l8nash#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff2?l8nash') format('woff2'),
    url('../fonts/icomoon.ttf?l8nash') format('truetype'),
    url('../fonts/icomoon.woff?l8nash') format('woff'),
    url('../fonts/icomoon.svg?l8nash#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Icon-awesome-facebook-f:before {
  content: "\e900";
  color: #fff;
}
.icon-Icon-awesome-instagram:before {
  content: "\e901";
  color: #fff;
}
.icon-Icon-awesome-linkedin-in:before {
  content: "\e902";
  color: #fff;
}
.icon-Icon-awesome-twitter:before {
  content: "\e903";
  color: #fff;
}
.icon-Icon-awesome-youtube:before {
  content: "\e904";
  color: #fff;
}
.icon-logo-whatsapp:before {
  content: "\e905";
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: var(--font-main) !important;
  color: var(--white);
}

h1 {
  font-size: ac(96px, 50px);
  font-weight: 600;

  line-height: 120%;
  
  margin-bottom: ac(18px, 10px);
  
  @media only screen and (max-width: 400px) {
    line-height: 120%;
  }
}

h2 {
  font-size: ac(46px, 30px);
  font-weight: 500;

  line-height: 152%;
  
  margin-bottom: 14px;
}

h3 {
  font-size: ac(30px, 21px);
  font-weight: 600;
  line-height: 153%;
  margin-bottom: 14px;
}

h4 {
  font-size: ac(26px, 18px);
  font-weight: 500;
  line-height: 130%;
  margin-bottom: ac(20px, 16px);
}

h5 {
  font-size: ac(22px, 16px);
  font-weight: 500;

  line-height: 1.5;

  margin-bottom: ac(18px, 10px);
}

h6 {
  font-size: ac(18px, 14px);
  font-weight: 500;
  letter-spacing: .3px;
  line-height: 2;
}

p {
  font-size: ac(18px, 15px);
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 166%;

  padding-bottom: ac(23px, 10px);
}

a {
  font-size: ac(16px, 14px);
  font-weight: 500;
  color: var(--white);
  display: inline-block;

  line-height: 1.5;
}

.inline-styles {
  div {
    background-color: transparent !important;
  }

  p {
    &:first-child {
      padding-bottom: 0;
    }
  }

  a.btn {
    display: inline-flex;
  }
  a:not(.btn, .job-card) {
    color: #E40512;
    font-size: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul {
    margin-bottom: 20px;
    li {
      font-size: ac(18px, 15px);
      font-weight: 400;
      letter-spacing: 0.3px;
      line-height: 166%;
      padding-bottom: 0;
      padding-left: 20px;
      position: relative;
      
      &::before {
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background-color: var(--white);
        left: 0;
        top: 11px;
      }
    }
  }
  ol {
    list-style-position: outside;
    padding-left: 20px;
    li {
      font-size: ac(18px, 15px);
      font-weight: 400;
      letter-spacing: 0.3px;
      line-height: 166%;
      padding-bottom: 0;
    }
  }

  p, li {
    & > * {
      background: transparent !important;
      & > * {
        background: transparent !important;
        & > * {
          background: transparent !important;
          & > * {
            background: transparent !important;
          }
        }
      }
    }
  }
}


.input {
  width: 100%;
  background-color: transparent;
  border: 2px solid var(--main);
  border-radius: 5px;
  color: var(--white);
  font-size: ac(18px, 16px);
  font-weight: 600;
  padding: 11px 20px;
  
  &::placeholder {
    color: var(--white);
    font-size: ac(18px, 16px);
    font-weight: 600;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #fff !important;
    }
}

textarea.input {
  min-height: 130px;
}

.form {
  .select2-container .select2-selection--single {
    height: 54px;
    border: 2px solid var(--main);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: var(--white);
    font-size: ac(18px, 16px);
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    background-image: url('../images/jobs/arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    right: 26px;
    top: 50%;
    transform: translateY(-50%);
  }
}