.btn {
  background-color: var(--main);
  border-radius: 5px;
  font-size: ac(18px, 15px);
  font-weight: 600;
  padding: ac(16px, 10px)  ac(41px, 26px);
  line-height: 122%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s ease;
  box-shadow: 0 3px 7px 4px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  
  &:hover {
    background-color: var(--primary);
    box-shadow: inset 0px -60px 0px 0px var(--primary);
  }
  
  &.second {
    background-color: transparent;
    transition: .2s ease;
    border: 2px solid var(--main);
    padding: ac(14px, 8px) ac(39px, 18px);
    
    &:hover {
      background-color: var(--main);
      box-shadow: inset 0px -60px 0px 0px var(--main);
    }

    &.mini-second {
      padding: ac(14px, 8px) ac(20px, 12px);
    }
  }
}

.btn-arrow {
  width: ac(44px, 36px);
  height: ac(44px, 36px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--white);
  border-radius: 5px;
  transition: .2s ease;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, .15);
  }

  svg {
    width: 14.2px;
    path, rect {
      transition: .2s ease;
    }
  }

  &:not(.swiper-button-disabled):hover {
    background-color: var(--white);

    svg {
      path, rect {
        fill: var(--primary)
      }
    }
  }

  &.swiper-button-disabled, &.disable {
    opacity: .4;
    /* cursor: auto; */
  }
}

.swiper-button-disabled {
  cursor: not-allowed;
}

.btn-arrow.slick-disabled {
  background-color: transparent;
  opacity: .4;
  cursor: not-allowed;
  
  &:hover {
    background-color: transparent;

    svg {
      path, rect {
        fill: var(--white) !important;
      }
    }
  }
}

.flockler-wall_v2-items a.flockler-btn-load-more {
  background-color: var(--main) !important;
  border-radius: 5px !important;
  font-size: ac(18px, 15px) !important;
  font-weight: 600 !important;
  padding: ac(16px, 10px)  ac(41px, 26px) !important;
  line-height: 122% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: .2s ease !important;
  color: white !important;
  box-shadow: none !important;
  
  &:hover {
    background: var(--primary) !important;
    color: var(--white) !important;
    /* box-shadow: inset 0px -60px 0px 0px var(--primary) !important; */
  }
  
  &:focus {
    background-color: var(--primary) !important;
    box-shadow: inset 0px -60px 0px 0px var(--primary) !important;
    color: white !important;
  }
}

section .flockler-wall_v2-items a.flockler-btn-load-more:hover {
  background: var(--primary) !important;
}