/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  transition: background ease-in-out 0.25s, height ease-in-out 0.25s, padding .25s ease;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  /* height: ac(120px, 75px); */
  padding: ac(25px, 20px) 0;
  display: flex;
  align-items: center;

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &.scrolled {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    /*height: ac(100px, 80px);*/
    padding: ac(20px, 16px) 0;

    &.active {
      background: transparent;
      backdrop-filter: none;
    }

    .logo {
      width: ac(160px, 110px);
    }

    .burger, .menu-toggle {
      margin-top: 0;

      &.white {
        border-color: var(--primary);

        span {
          background: var(--primary);
        }
      }
    }
  }

  .logo {
    /* height: 100%; */
    width: ac(174px, 120px);
    flex-shrink: 0;
    transition: .2s ease;

    &::before {
      display: none;
    }

    &:hover {
      transform: scale(1.04);
    }

    svg {
      width: 100%;
      height: auto;
    }

    img {
      width: 100%;
      /* height: 100%; */
      object-fit: contain;
    }

    path {
      transition: all .3s ease;
    }

    &.active {
      g {
        path {
          fill: var(--white);
        }
      }
    }
  }

  &.header-second {
    .logo {
      g {
        path {
          fill: var(--white);
        }
      }
    }

    .desk-nav {
      &__link {
        color: var(--white);

        &:hover {
          color: var(--main);
          }
      }

      .more-plus svg path {
        fill: var(--white);
      }
    }

    &.scrolled {
      .logo {
        g {
          path {
            fill: var(--primary);
          }
        }

        &.active {
          g {
            path {
              fill: var(--white);
            }
          }
        }
      }

      .desk-nav {
        &__link {
          color: var(--primary);

          &:hover {
            color: var(--main);
          }
        }

        .more-plus svg path {
          fill: var(--primary);
        }
      }
    }
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .burger {
    /* display: none; */
  }

  .desk-nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: ac(67px, 30px);

      &.active {
        .desk-nav__link {
          color: var(--white);

          &:hover {
            color: var(--white);
            opacity: .5;
          }

          .more-plus {
            svg {
              path {
                fill: var(--white);
              }
            }
          }
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      color: var(--primary);
      transition: color .25s ease, opacity .25s ease;

      &:not(:last-child) {
        margin-right: ac(40px, 15px);
      }

      .more-plus {
        width: 13px;
        height: 13px;
        /*background-image: url("../images/icons/plus.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;*/
        margin-left: 7px;
        transition: transform .25s ease;

        svg {
          width: 100%;
          height: 100%;

          path {
            transition: all .3s ease;
          }
        }
      }

      &.active {
        .more-plus {
          transform: rotate(-45deg) scale(1.3) translateY(5%);
        }
      }

      &:hover {
        color: var(--main);
      }
    }

    .btn {
      font-size: 16px;
      padding: 14px 32px;
      font-weight: 500;
    }

    @media (--mobile-menu-start-point) {
      margin-left: auto;

      .desk-nav__list {
        display: none;
      }

      .btn {
        margin-right: 30px;
      }

      @mixin mob-lg {
        display: none;
      }
    }
  }

  .navbar-nav {
    display: flex;
    flex-grow: 1;
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .menu-item {
    margin-right: 20px;
    position: relative;

    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: "\e999";
          font-family: "icomoon";
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  &:not(.header-mobile) {
    .menu-item {
      @media (--mobile-menu-end-point) {
        padding: 5px 0;
      }

      &:hover {
        @media (--mobile-menu-end-point) {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: ac(16px, 14px);
    @mixin transition-all;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 2px;
      background: var(--white);
      @mixin transition-all;
    }

    &:hover {
      color: var(--primary);

      &::before {
        width: 100%;
        left: 0;
        background: var(--primary);
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--bg-second);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (--mobile-menu-start-point) {
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
    }

    .navbar-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 50%;
      height: 100vh;
      min-width: 320px;
      padding-top: 100px;
      background: var(--bg-second);
      padding-right: 5px;
      padding-bottom: 40px;
      transition: transform 0.3s ease-out;

      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }

      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      .menu {
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 40px;
        align-items: flex-start;
        align-self: center;

        &::-webkit-scrollbar {
          width: 3px;
          height: 2px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.5);
          border-radius: 3px;
          cursor: pointer;
        }
      }

      .menu-link {
        padding: 10px 0;
        font-size: ac(30px, 22px);
      }
    }

    .dropdown-menu {
      position: relative;
      top: 0;
    }
  }
}

/* end of Common desktop header Mobile HEADER */

/* ----------------------- ONLY Mobile HEADER ----------------------- */
.header.header-mobile {
  .burger {
    display: flex;
  }

  .navbar-nav {
    position: fixed;
    right: 0;
    top: 0;
    width: 50%;
    height: 100vh;
    min-width: 320px;
    padding-top: 100px;
    background: var(--bg-second);
    padding-right: 5px;
    padding-bottom: 40px;
    transition: transform 0.3s ease-out;

    &.nav-slide-left {
      right: auto;
      left: 0;
      transform: translateX(-100%);

      &.active {
        transform: translateX(0);
      }
    }

    &.nav-slide-right {
      right: 0;
      left: auto;
      transform: translateX(100%);

      &.active {
        transform: translateX(0);
      }
    }

    &.nav-slide-down {
      right: auto;
      left: 0;
      transform: translateY(-100%);

      &.active {
        transform: translateY(0);
      }
    }

    &.nav-slide-up {
      right: auto;
      left: 0;
      transform: translateY(100%);

      &.active {
        transform: translateY(0);
      }
    }

    .menu {
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 40px;
      align-items: flex-start;
      align-self: center;

      &::-webkit-scrollbar {
        width: 3px;
        height: 2px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 3px;
        cursor: pointer;
      }
    }

    .menu-link {
      padding: 10px 0;
      font-size: ac(25px, 18px);
    }
  }

  .dropdown-menu {
    position: relative;
    top: 0;
  }
}

/* end of ONLY Mobile HEADER */

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: ac(75px, 43px, 375, 1025);
  height: ac(75px, 43px, 375, 1025);
  border: 2px solid var(--primary);
  border-radius: 100%;
  display: none;
  transition: border-color .25s ease;

  @mixin tab-md {
    display: flex;
  }

  /*@media only screen and (max-width: 400px) {
    margin-top: 10px;
  }*/

  &.white {
    border-color: var(--white);
    span {
      background: var(--white);
    }
  }

  span {
    width: 55%;
    height: 1px;
    background: var(--primary);
    transition: all 0.1s;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: "";
      left: 0;
      width: 65%;
      height: inherit;
      background: inherit;
      transition: all 0.1s;
    }

    @mixin min-media 768 {
      height: 2px;
    }

    &::before {
      top: -7px;

      @media only screen and (max-width: 400px) {
        top: -4px;
      }
    }

    &::after {
      bottom: -7px;
      left: 35%;

      @media only screen and (max-width: 400px) {
        bottom: -4px;
      }
    }
  }

  @mixin transition-all;

  @mixin min-media 1025 {
    &:hover {
      /* transform: scale(1.1); */
      span {
        &::before {
          width: 100%;
        }
        &::after {
          width: 100%;
          left: 0;
        }
      }
    }
  }

  &.active {
    border-color: var(--primary);

    span {
      background: transparent;

      &::before {
        width: 100%;
        top: 0;
        transform: rotate(-45deg);
        background: var(--white);
      }
      
      &::after {
        width: 100%;
        top: 0;
        left: 0;
        transform: rotate(45deg);
        background: var(--white);
      }
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background-image: url('../images/hero-bg.jpg');
  background-size: 100% 100%;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  padding-top: 207px;
  
  &__inner {
    height: 100%;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #E40512;
    
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #0f213d; 
    }
    &::-webkit-scrollbar-thumb {
      background: var(--main); 
    }
  }

  @mixin transition-all;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    /* cursor: pointer; */
  }

  .wave {
    /* position: absolute;
    bottom: -30px; */
    width: 100%;
    height: auto;
    z-index: 20;
    pointer-events: none;
    user-select: none;
    margin-top: -180px;
  }

  .bottom-menu {
    width: 100%;
    box-shadow: 0px 1000px 0 1000px #0e2450;
  }

  .cont {
    /* max-width: 970px;
    width: perc(970); */
  }
}

.header__full {
  width: 100%;
  /* padding-bottom: 257px; */
  display: flex;
  justify-content: space-between;
  position: relative;

  .menu-btn-contact {
    display: none;

    @mixin mob-lg {
      display: flex;
      order: 5;
      margin-top: 30px;
      max-width: 180px;
    }
  }


  h2 {
    font-size: ac(34px, 30px);
  }

  &__left {
    display: flex;
    flex-direction: column;
    a {
      font-size: ac(38px, 26px);
      font-weight: 500;
      line-height: 1.6;
      transition: opacity .2s ease;
  
      /* &:hover {
        text-decoration: underline;
      } */

      &.active {
        opacity: 1 !important;
      }
    }
  }

  &__right {
    /* width: 100%;
    max-width: 530px; */

    a {
      font-size: ac(28px, 22px);
      font-weight: 300;
      line-height: 1.6;
      position: relative;
      display: inline-block;
      
      &::before {
        position: absolute;
        content: '';
        width: 0%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #fff;
        transition: width .2s ease;
      }
  
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }

    .bl {
      display: flex;
      flex-direction: column;
      margin-top: -10px;
      max-width: 510px;
      padding-left: ac(31px, 18px);
    }
  }
}

.head_accordion {
  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease;
    padding-left: 20px;
  }
}

.header__full__left .head_accordion {
  &__content {
    div a {
      font-size: ac(38px, 22px) !important;
    }
  }
}

/* end of HEADER COMPONENTS */


@media only screen and (max-width: 1300px) {
  .header-close-wrapper {
    .cont {
      max-width: 1170px;
      width: perc(1170);
    }
    .wave {
      margin-top: -100px;
    }
  }

  .header__full {
    justify-content: space-between;
    &__right {
      margin-left: 40px;
      max-width: 415px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .header__full__left a {
    font-size: 26px;
  }
  .header__full__right {
    h2 {
      font-size: 30px;
    }
    a {
      font-size: 22px;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .header-close-wrapper .wave {
    margin-top: -60px;
  }
}

@media only screen and (max-width: 950px) {
  .header__full {
    flex-wrap: wrap;
    &__left {
      width: 50%;
      order: 1;

      @mixin mob-xl {
        width: 90%;
      }
    }
    &__right {
      order: 3;
      margin-left: 0;
      margin-top: 40px;

      &.second {
        order: 2;
        margin-top: 0;
      }
    }
  }
} 

@media only screen and (max-width: 800px) {
  .header__full__right {
    max-width: 335px;
  }
}
.header__full__right.second {
  max-width: unset;
  width: unset;

  h2 {
    max-width: 340px;
    white-space: nowrap;

    @mixin media 1350 {
      max-width: 320px;
      white-space: normal;
    }
  }
}
@media only screen and (max-width: 700px) {
  .header__full {
    flex-direction: column;
    padding-bottom: 30px;
    
    &__right {
      max-width: 100%;
      margin-left: 0;
      margin-top: 30px;
    }
  }
  
  .header__full__left.first {
    margin-bottom: 40px;
  }
  
  .header-close-wrapper {
    padding-top: 130px;
  }

  .header-close-wrapper .wave {
    margin-top: 0px;
  }
}

@media only screen and (max-height: 900px) {
  .header-close-wrapper .wave {
    /* bottom: -75px !important; */
  }
}

@media only screen and (max-width: 1024px) {
  .header-close-wrapper .wave {
    /* bottom: 0px !important; */
  }
}

@media only screen and (max-width: 500px) {
  .header__full {
    /* padding-bottom: 150px; */
  }
}