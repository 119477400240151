.first-sec.jobs-page {
  @media only screen and (max-width: 1445px) {
    padding-bottom: 222px;
  }
  form {
    max-width: 592px;
    width: 100%;
    margin: 38px auto 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 21px 17px;

    input.input {
      grid-column: 1 / 3;
      width: 100%;
    }

    .select2-container {
      width: 100% !important;
    }
    
    .form__bottom {
      grid-column: 1 / 3;
    }

    .btn {
      display: inline-flex;
    }

    .width-full {
      grid-column: 1 / 3;
      & ~ .select2-container {
        grid-column: 1 / 3;
      }
    }
  }
}

.jobs {
  margin-top: -150px;
  position: relative;
  z-index: 20;
  &__top {
    display: flex;
    justify-content: flex-end;

    .select2-container {
      max-width: 185px;
      min-width: 157px;
      width: auto !important;
    }

    .select2-container .select2-selection--single {
      border: 0;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: var(--white);
      font-weight: 800;
      font-size: ac(18px, 16px);
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      background-image: url('../images/jobs/arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 13px;
    }
  }

  &__grid {
    padding-bottom: 30px;
    margin-top: 62px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 54px 10px;
  }

  .job-card {
    max-width: 420px;
    padding-top: 47px;
    padding-bottom: 8px;

    h3 {
      line-height: 142%;
    }
  }

  &__pagination {
    margin-top: 80px;
    display: flex;
    justify-content: center;

    .btn-arrow {
      width: ac(54px, 36px);
      height: ac(54px, 36px);

      &:first-child {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.job-page-form {
  form {
    position: relative;
    min-height: 87px;
  }
  h3.title-small {
    position: absolute;
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
  }
}

.talk.jobs-page {
  margin-top: 88px;

  .cont:before {
    display: none;
  }
}

@media only screen and (max-width: 1420px) {
  .talk.jobs-page .wave {
    top: -100px;
  }
}

@media only screen and (max-width: 1200px) {
  .jobs {
    margin-top: -100px;
  }
}

@media only screen and (max-width: 1024px) {
  .jobs__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 900px) {
  .talk.jobs-page {
    padding-top: 140px;

    .wave {
      top: -70px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .talk.jobs-page .wave {
    top: -30px;
  }
}

@media only screen and (max-width: 550px) {
  .first-sec.jobs-page form {
    grid-template-columns: 1fr;

    input.input {
      grid-column: 1 / 1;
    }
    
    .form__bottom {
      grid-column: 1 / 1;
    }
    
    .width-full ~ .select2-container {
      grid-column: 1 / 1;
    }
  }

  .jobs__grid {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .jobs {
    margin-top: -60px;
  }
}

@media only screen and (max-width: 410px) {
  .job-page-form {
    padding-bottom: 40px;
  }
}
