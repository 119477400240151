.first-sec.specialisms-page {
  @media only screen and (max-width: 1445px) {
    padding-bottom: 275px;
  }

  &:before {
    transform: scale(1.3);
    left: -6vw;
    background: radial-gradient(circle, rgb(9, 26, 61) 0%, rgb(9, 26, 61) 20%, rgba(5,36,83,0) 70%);
  }

  h1 {
    word-break: break-all;
  }

  .cont {
    padding-bottom: 150px;
    
    @media only screen and (max-width: 1200px) {
      padding-bottom: 40px;
      padding-top: 40px;
    }

    @media only screen and (max-width: 900px) {
      padding-top: 80px;
      padding-bottom: 0px;
    }
  }
}


.sec-about.specialisms-page {
  margin-top: -178px;

  .sec-about {
    &__content {
      max-width: 1030px;
    }
  }
  .cont:before {
    top: -385px;
  }

  @media screen and (max-width: 750px) {
    margin-top: 170px !important;
  }
  
  @media screen and (max-width: 450px) {
    margin-top: 60px !important;
    .cont:before {
      display: none;
    }
  }
}

.sectors-supp {
  padding-top: 209px;
  background-image: url('../images/specialisms/bg.jpg');
  background-position: bottom;
  background-size: cover;
  padding-bottom: 94px;
  position: relative;

  .wave {
    position: absolute;
    top: -80px;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
  }

  &__top {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    z-index: 99;

    h2 {
      line-height: 100%;
    }

    p {
      letter-spacing: 0px;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 100;
  }

  &__left {
    width: 50%;
    padding-top: 23px;
    padding-right: 7px;
    max-height: 600px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--bg);
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--main);
    }

    button {
      position: relative;
      width: 100%;
      text-align: left;
      font-size: ac(30px, 21px);
      border-top: 1px solid var(--white);
      border-bottom: 1px solid var(--white);
      font-weight: 300;
      padding: 8px 50px 8px 0;
      color: rgba(255, 255, 255, .6);
      transition: color .2s ease;

      &:hover {
        color: var(--white);
      }

      &.active {
        color: var(--white);
        &::before {
          transform: rotate(-90deg);
        }
      }

      &::before {
        position: absolute;
        content: '';
        width: 12px;
        height: 7px;
        background-image: url('../images/specialisms/arrow.svg');
        background-size: cover;
        top: calc(50% - 4px);
        right: 30px;
        transition: transform .2s ease;
      }
    }
  }

  &__right {
    max-width: 535px;
    width: 100%;
    margin-left: 20px;
    padding-top: 20px;
  }

  &__content {
    display: none;
    &.active {
      display: block;
    }
    ul li {
      font-size: ac(21px, 16px);
      font-weight: 300;
      margin-bottom: 5px;

      &::before {
        top: 15px;
      }
    }

    img {
      width: 100%;
      border-radius: 28px;
      margin-top: 41px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 55px;

    .btn {
      width: ac(238px, 170px);
      margin-bottom: 20px;
    }
  }
  
  &__btn {
    width: ac(245px, 180px);
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

}

.featured-jobs.specialisms-page {
  .featured-jobs__left {
    margin-right: ac(25px, 25px);
    max-width: 470px;
    width: 470px;
    margin-top: -5px;
  }
}

.news.people-sec {
  padding-bottom: 100px;
  background-color: #fff;

  &.gradient-bg {
    position: relative;
    overflow: visible;
    margin-top: 0;
    padding-top: ac(160px, 60px);
    padding-bottom: ac(200px, 150px);
    margin-bottom: ac(-100px, -50px);
    background: linear-gradient(180deg, color-mod(#fff a(0%)) 0%, #d5eff7 35%, rgba(255,255,255,1) 45%, rgba(255,255,255,1) 60%, #d5eff7 70%, color-mod(#fff a(0%)) 100%);

    &:before,
    &:after {
      background: radial-gradient(circle,rgba(41,86,226,.7) 0,rgba(41,86,226,0) 60%);
      border-radius: 100%;
      content: "";
      height: min(max(calc(118.57143px + 68.15476vw),642px),1100px);
      pointer-events: none;
      position: absolute;
      user-select: none;
      width: min(max(calc(118.57143px + 68.15476vw),642px),1100px);
      z-index: 1;
      display: block !important;
    }

    &:before {
      top: 0;
      left: 0;
      transform: translateX(-50%) translateY(-50%);
    }

    &:after {
      bottom: 0;
      right: 0;
      transform: translateX(50%) translateY(50%);
    }

    .cont {
      position: relative;
      z-index: 1;
    }

    .blur-decor {
      display: none;
      pointer-events: none;

      @mixin aspect-ratio 1440, 805;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;

      &--first {
        transform: rotate(180deg) translateY(90%);
      }

      &--second {
        transform: translateY(-5%);
      }
    }

    h2 {
      color: var(--white) !important;
    }

    .bl::before {
      background-color: var(--white) !important;
    }

    .btn-arrow {
      border-color: var(--white) !important;

      svg {
        path {
          fill: var(--white) !important;
        }
        rect {
          fill: var(--white) !important;
        }
      }
      
      &:hover {
        svg {
          path {
            fill: var(--black) !important;
          }
          rect {
            fill: var(--black) !important;
          }
        }
      }
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: var(--white) !important;
    }

    .select2-container .select2-selection--single {
      border-color: var(--white) !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      background-image: url('../images/chevron-down-outline.svg') !important;
      background-position: 100% 100%;
    }

    @mixin tab {

      background: linear-gradient(180deg, color-mod(#fff a(0%)) 0%, rgba(255,255,255,1) 30%, rgba(130,146,169,1) 80%, color-mod(#fff a(0%)) 100%);

      .blur-decor {
        display: none;
      }
    }

    /*&:before {
      content: "";
      position: absolute;
      background: linear-gradient(180deg, rgba(5,36,83,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 70%, rgba(5,36,83,1) 100%);
      top: -50px;
      bottom: -50px;
      left: -50px;
      right: -50px;
      filter: blur(15px);

    }*/
  }

  &.gradient-view {
    position: relative;
    background: transparent;

    &:after {
      display: block !important;
      content: "";
      position: absolute;
      top: -50px;
      left: -100px;
      right: -100px;
      bottom: -80px;
      background: linear-gradient(180deg,color-mod(#fff a(0%)) 0%, #fff 40%, #fff 80%, color-mod(#fff a(0%)) 100%);
      filter: blur(50px);
    }

    &:before {
      background: radial-gradient(circle,rgba(41,86,226,.7) 0,rgba(41,86,226,0) 60%);
      border-radius: 100%;
      content: "" !important;
      height: ac(800px, 500px);
      pointer-events: none;
      position: absolute;
      user-select: none;
      width: ac(800px, 500px);
      z-index: 1;
      display: block !important;
      bottom: 0;
      right: 0;
      transform: translateX(50%) translateY(80%);
      left: auto;
    }

    .cont {
      position: relative;
      z-index: 1;
    }

    &.people-sec {
      .news__top h2 {
        color: white;
      }

      .bl::before {
        background-color: var(--white) !important;
      }

      .person-card {
        .bl::before {
          background-color: black !important;
        }
      }

      .btn-arrow {
        border-color: var(--white) !important;

        svg {
          path {
            fill: var(--white) !important;
          }
          rect {
            fill: var(--white) !important;
          }
        }

        &.swiper-button-disabled:hover {
          svg {
            path {
              fill: var(--black) !important;
            }
            rect {
              fill: var(--black) !important;
            }
          }
        }
      }

      .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: var(--white) !important;
      }

      .select2-container .select2-selection--single {
        border-color: var(--white) !important;
      }

      .select2-container--default .select2-selection--single .select2-selection__arrow {
        background-image: url('../images/chevron-down-outline.svg') !important;
        background-position: 100% 100%;
      }
    }
  }


  
  .news__top {
    h2 {
      color: #000;
    }

    .select2-container .select2-selection--single {
      border: 1px solid var(--black);
      height: 55px;
      padding: 0 20px 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      top: 43%;
      right: 10px;
      transform: translateY(-50%);
      background-image: url('../images/chevron-down-outline-black.svg');
      transition: transform .2s ease;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #000;
    }

    .select2-container--open .select2-selection__arrow {
      transform: rotate(180deg) translateY(8px) !important;
    }
    
    .btns-row {
      margin-left: 20px;
      flex-wrap: nowrap;
    }
    .btn-arrow {
      border-color: #000;

      svg {
        path, rect {
          fill: #000;
        }
      }

      &:hover {
        background-color: rgb(237, 236, 236);
      }

      &.swiper-button-disabled:hover {
        background-color: #fff;
      }
    }
  }

  .swiper-wrapper > h3 {
    color: #000;
    text-align: center;
    display: block;
    width: 100%;
  }

  .person-card__name h3 {
    color: #000;
  }
  .person-card .bl p {
    color: #000;
  }
  .person-card .bl .btn {
    color: var(--main);

    &:hover {
      color: #fff;
    }
  }

  .bl {

    &::before {
      background-color: #000;
    }
  }
}

.news.specialisms-page {
  z-index: 99;
  padding-top: 180px;
  &::before {
    display: none;
  }

  .cont::after {
    display: none;
  }

  .bl {
    padding-left: ac(25px, 18px);
  }

  .cont-sm {
    max-width: 1000px;
    width: perc(1000);
  }
}

.news.people-sec.gradient-view {
  padding-top: 100px;
}

.sec-about.specialisms-page-2 {
  margin-top: 206px;

  .sec-about__content {
    margin-top: 34px;

    .btn {
      max-width: 244px;
      width: 100%;
    }
  }
}

.people-sec {
  margin-top: 157px;

  .news__top h2 {
    margin-left: 5px;
  }

  .people__slider {
    margin-top: 13px;
    overflow: visible;

    .swiper-wrapper {
      align-items: stretch;
    }

    .swiper-slide {
      height: auto;
    }
  }

  .person-card .bl {
    padding-left: ac(32px, 18px);
    span {
      color: var(--main);
      font-size: ac(21px, 16px);
      font-weight: 600;
      margin-bottom: 6px;
      display: block;
    }
  }
}

.talk.specialisms-page {
  margin-top: 90px;

}

@media only screen and (min-width: 2050px) {
  .sectors-supp {
    padding-top: 10%;
    .wave {
      top: -150px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .sec-about.specialisms-page {
    margin-top: -120px;
  }
}

@media only screen and (max-width: 1100px) {
  .sectors-supp__right {
    max-width: 440px;
  }
}

@media only screen and (max-width: 900px) {
  .sectors-supp__right {
    max-width: 365px;
  }

  
  .news.specialisms-page {
    padding-top: 100px;
  }
  .news.people-sec.gradient-view {
    padding-top: 0px;
  }

  .news.people-sec {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 80px;
  }
}

@media only screen and (max-width: 800px) {
  .sectors-supp .wave {
    width: 200%;
    left: -50%;
    top: -70px;
  }

  .sec-about.specialisms-page {
    .cont:before {
      top: -78%;
    }
    .cont:after {
      bottom: -50%;
    }
  }

  .sec-about.specialisms-page-2 {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 768px) {
  .sectors-supp__inner {
    flex-direction: column;
  }
  .sectors-supp__left {
    width: 100%;
  }
  .sectors-supp__right {
    margin-top: 20px;
    max-width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 700px) {
  .news.people-sec .bl {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .news.people-sec .bl > .flex {
    flex-direction: column;
    align-items: flex-start !important;

    .people-nav {
      margin-left: 0;
      margin-top: 10px !important;
    }

  }
  .sec-about.join-the-team-2 .cont:after {
    z-index: -1;
  }

  .featured-jobs.specialisms-page .featured-jobs__left {
    width: 100%;
  }

  .sectors-supp .wave {
    top: -25px;
  }

  .sec-about.specialisms-page-2 {
    margin-top: 0;
  }

  .news.specialisms-page {
    .news__top {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .sectors-supp__left button {
    padding-right: 35px;
    &:before {
      right: 15px;
    }
  }
}

.hero {
  + .sec-about .cont::after {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 0;
  }
}

.clients-say-section {
  padding-top: ac(216px, 80px);
  position: relative;

  &:after, &:before {
    border-radius: 100%;
    content: "";
    pointer-events: none;
    position: absolute;
    user-select: none;
  }

  &:before {
    background: radial-gradient(circle,rgba(209,46,37,.7) 0,rgba(5,36,83,0) 70%);
    height: ac(600px, 450px);
    left: 0;
    bottom: 0;
    width: ac(600px, 450px);
    transform: translateY(40%) translateX(-20%);
  }

  &:after {
    background: radial-gradient(circle,rgba(41,86,226,.7) 0,rgba(41,86,226,0) 60%);
    top: 50%;
    height: ac(950px, 500px);
    left: 0;
    width: ac(950px, 500px);
    transform: translateY(-45%) translateX(75%);
  }

  &__wrap {
    position: relative;
    z-index: 1;

    h3 {
      margin-bottom: 0;
      font-weight: 300;
    }
  }

  .clients-say-slider {
    padding-top: ac(37px, 30px);
    width: 100%;
    max-width: 100%;

    &__item {
      padding-left: ac(304px, 30px);
      font-style: italic;

      blockquote {
        max-width: 732px;

        p, em {
          font-size: ac(18px, 15px);
          font-weight: 400;
          letter-spacing: 0.3px;
          line-height: 166%;
          padding-bottom: ac(23px, 10px);
          font-family: var(--font-main);
          font-style: italic;

          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .clients-say-slider-pagination {
    padding-top: ac(103px, 40px);
    list-style-type: none;
    counter-reset: num;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    li {
      position: relative;
      opacity: .5;
      transition: opacity .25s ease;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:not(:last-child) {
        margin-right: ac(20px, 12px);
      }

      &:after {
        content: "";
        background: var(--white);
        height: 2px;
        width: 0;
        transition: margin-left .25s ease, width .3s ease;
        pointer-events: none;
      }

      &:before {
        font-family: var(--font-main);
        font-size: ac(30px, 21px);
        font-weight: 600;
        line-height: 153%;
        letter-spacing: 0.3px;
        content: "0" counter(num);
        counter-increment: num;
      }

      &:hover {
        opacity: .7;
      }

      &:nth-child(n + 10) {
        &:before {
          content: counter(num);
        }
      }

      &.active {
        opacity: 1;

        &:after {
          margin-left: ac(20px, 12px) !important;
          width: ac(159px, 30px) !important;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
