.our-services.solutions-page {
  .cont.cont-lg {
    max-width: 1340px;
    width: perc(1340);
  }
  .our-services__top {
    margin-bottom: 76px;
    max-width: 100%;
    .swiper-wrapper {
      max-width: 100%;
    }

    .btn {
      margin: 0 12px 20px;
      white-space: nowrap;
    }
  }
}

.solution-block {
  margin-bottom: 94px;
  position: relative;
  &__anchor {
    position: absolute;
    left: 0;
    top: -200px;

    @media only screen and (max-width: 768px) {
      top: -120px;
    }
  }
  &:last-child {
    .solution-block__bottom {
      border-bottom: none;
    }
  }
  &__bottom {
    padding-bottom: 100px;
    border-bottom: 1px solid rgba(255,255,255,.15);
  }
  &__inner {
    display: flex;
    align-items: flex-start;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: ac(900px, 642px);
      height: ac(900px, 642px);
      background: radial-gradient(circle, rgba(41,86,226,.7) 0%, rgba(41,86,226,0) 60%);
      border-radius: 100%;
      top: -15%;
      left: -45%;
      z-index: -15;
      user-select: none;
      pointer-events: none;
    }

    &::after {
      position: absolute;
      content: '';
      width: ac(776px, 676px);
      height: ac(776px, 676px);
      background: radial-gradient(circle, rgba(209,46,37,0.7) 0%, rgba(5,36,83,0) 70%);
      border-radius: 100%;
      bottom: -140%;
      right: -35%;
      z-index: -1;
      user-select: none;
      pointer-events: none;
    }
  }
  &__right {
    width: 38%;
    display: flex;
    justify-content: flex-end;
    margin-left: 30px;
  }
  &__left {
    width: 62%;
    margin-top: 11px;

    .bl {
      max-width: 610px;
      h3 {
        font-weight: 300;
        margin-bottom: ac(50px, 30px);
      }
    }
  }

  iframe {
    border-radius: 0;
  }

  &__video {
    width: 100%;
    margin-bottom: ac(40px, 30px) !important;

    &:before {
      content: none;
      display: none;
    }

    iframe {
      border-radius: 0 !important;

    }

    .vid1-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
      padding-top: 56.2%;
    }

    .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
      transition: .2s ease;
      background-image: url('../images/join-the-team/play-white.svg');
      background-repeat: no-repeat;
    }

    .vid1-dimensions {
      border-radius: 0;
    }
  }

  &__content {
    padding-left: ac(150px, 70px);

    p {
      padding-bottom: ac(15px, 10px);
    }

    .btn {
      display: inline-flex;
      /*max-width: 190px;*/
      width: auto;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .btn {
      &:nth-child(2) {
        margin-left: 20px;
      }

      &.second {
        padding: ac(14px, 8px)  ac(43px, 18px);
      }
    }

    @media screen and (max-width: 395px) {
      flex-direction: column;
      align-items: flex-start;

      .btn {
        margin-bottom: 16px;

        &:nth-child(2) {
          margin-left: 0px;
        }
      }
    }
  }
}

.solution-card {
  max-width: 395px;
  width: 100%;
  border-radius: 28px;
  background-image: url('../images/solutions/solution-card.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 46px 42px;
  h3 {
    font-weight: 300;
  }

  ul {
    li {
      font-size: ac(21px, 16px);
      font-weight: 300;
      padding-left: 23px;
      margin-bottom: 5.3px;
      letter-spacing: 0px;

      &::before {top: 14px;}
    }
  }

  &.with-icons {

    h3 {
      margin-bottom: ac(20px, 15px);
    }

    ul {
      padding-bottom: 10px;
      li {
        padding-left: 36px;
        font-size: ac(21px, 16px);
        font-weight: 300;
        letter-spacing: 0;
        line-height: 161.90%;
        margin-bottom: 10px;
        position: relative;

        p {
          font: inherit;
          padding-bottom: 0;
        }
      }
    }

    .with-icons-list {
      &__item-logo {
        position: absolute;
        left: 0;
        top: ac(6px, 2px);
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.benefits-list {
  margin-top: 40px;

  .bl {
    padding-left: ac(30px, 15px);

    h3 {
      font-size: ac(30px, 21px);
      font-weight: 300;
      line-height: 123.33%;
      margin-bottom: 0;
      max-width: 558px;
    }
  }

  &__container {
    padding-top: ac(78px, 30px);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ac(100px, 30px);
    grid-row-gap: ac(40px, 20px);
    position: relative;


    &:before {
      position: absolute;
      content: "";
      background-image: url("../images/decor-statistics.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: ac(306px, 173px);
      height: ac(291px, 165px);
      right: ac(183px, 0px);
      bottom: ac(-10px, -5px);

      @mixin mob-xl {
        opacity: .1;
      }
    }

    @mixin mob-xl {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 30px;
    }
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    z-index: 2;
  }

  &__logo {
    width: ac(170px, 80px);
    min-width: ac(170px, 80px);
    height: ac(170px, 80px);
    padding: ac(36px, 20px);
    margin-right: ac(30px, 15px);
    background: var(--primary);
    border-radius: ac(20px, 10px);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    p {
      font-size: ac(21px, 16px);
      letter-spacing: 0.3px;
      line-height: 155%;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.benefits-slider {
  margin-top: 115px;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bl {
      padding-left: ac(30px, 15px);

      &::before {
        top: 6px;
        height: calc(100% - 25px);
      }
    }

    h3 {
      font-weight: 300;
    }
  }
  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .swiper-wrapper, .swiper-slide {
    height: auto !important;
    
    img {
      margin: 0 auto 20px;
    }
    h5 {
      text-align: center;
    }
  }
  &__btns {
    display: flex;
    margin-top: -14px;
  
    .btn-arrow {
      width: ac(54px, 36px);
      height: ac(54px, 36px);

      &:first-child {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &__inner {
    margin-top: 82px;
    /* transform: translateX(23px); */
    img {
      max-width: 98px;
      width: 100%;
    }
    h5 {
      margin-top: 7px;
      font-size: ac(21px, 16px);
      font-weight: 300;
    }
  }
}

.talk.solutions-page {
  margin-top: -96px;
}

@media only screen and (max-width: 1200px) {
  .benefits-slider__inner {
    transform: none;
  }
  .solution-block {
    &__left {
      width: 55%;
    }
    &__right {
      width: 45%;
    }
    &__content {
      padding-left: 55px;
    }
    &__inner:after {
      bottom: -80%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .solution-block {
    &__left {
      width: 100%;
      margin-bottom: 40px;
    }
    &__right {
      margin-left: 0;
      width: 100%;
      justify-content: flex-start;
    }
    &__inner {
      flex-direction: column;
      &:after {
        bottom: -50%;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .solution-block__content {
    padding-left: 0;
  }
}

@media only screen and (max-width: 550px) {
  .benefits-slider__top {
    flex-direction: column;
    align-items: flex-start;

    .bl {
      margin-bottom: 20px;
    }
  }

  .solution-card {
    padding: 37px 27px 34px;
  }
}

@media only screen and (max-width: 440px) {
  .benefits-slider .cont.cont-lg {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 380px) {
  .solution-block__inner:after {
    bottom: -45%;
    right: -130%;
  }
}