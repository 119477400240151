.our-services {
  position: relative;
  z-index: 20;
  margin-top: -50px;
  margin-bottom: 130px;

  &.services-page {
    margin-top: ac(-195px, -50px);
  }

  &__top {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 0;
    margin-bottom: 28px;
    
    .swiper-wrapper {
      height: auto !important;
    }

    .btn {
      display: inline-flex;
      margin: 0 8px 20px;
      padding: ac(16px, 10px)  ac(44px, 26px);
    }

    .swiper-slide {
      width: auto;
      max-height: 73px;
    }
  }
}

.service-block {
  position: relative;
  border-bottom: 1px solid rgba(255,255,255, .15);
  padding-bottom: ac(100px, 70px);
  margin-bottom: ac(92px, 70px);
  &__anchor {
    position: absolute;
    left: 0;
    top: -200px;

    @media only screen and (max-width: 768px) {
      top: -120px;
    }
  }
  &:last-child {
    border: none;
  }
  &:nth-child(odd) {
    &::after {
      position: absolute;
      content: '';
      width: ac(776px, 676px);
      height: ac(776px, 676px);
      background: radial-gradient(circle, rgba(209,46,37,0.7) 0%, rgba(5,36,83,0) 70%);
      border-radius: 100%;
      top: -35%;
      right: -35%;
      z-index: -1;
      user-select: none;
      pointer-events: none;
    }
  }
  &:nth-child(even) {
    &::after {
      position: absolute;
      content: '';
      width: ac(900px, 642px);
      height: ac(900px, 642px);
      background: radial-gradient(circle, rgba(41,86,226,.7) 0%, rgba(41,86,226,0) 60%);
      border-radius: 100%;
      top: -15%;
      left: -45%;
      z-index: -15;
      user-select: none;
      pointer-events: none;
    }
  }
  &__inner {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
  }

  &__left {
    width: 50%;
    padding-right: 8px;
    .bl {
      h3 {
        font-weight: 300;
      }
    }
  }

  &__right {
    width: 50%;
    padding-left: 34px;
    padding-top: 2px;

    p {
      padding-bottom: ac(32px, 10px);
    }

    .btn {
      display: inline-flex;
      margin-top: 8px;
      padding: ac(14px, 8px)  ac(41px, 18px);
    }
  }

  &__image {
    width: 100%;
    position: relative;
    border-radius: 28px;
    overflow: hidden;
    margin-top: 43px;

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }

    &::after {
      position: relative;
      content: '';
      width: 100%;
      padding-bottom: 54%;
      display: block;
    }
  }


  &__video {
    .video-js .vjs-big-play-button .vjs-icon-placeholder::before {
      background-image: url('../images/join-the-team/play-white.svg');
      background-repeat: no-repeat;
    }

    &:before {
      display: none;
    }


    iframe {
      height: ac(326px, 200px) !important;

      @mixin tab-sm {
        height: 47vw !important;
      }
    }



  }
}

.talk.services-page {
  margin-top: -34px;

  .cont::before {
    display: none;
  }
}

@media only screen and (max-width: 1420px) {
  .talk.services-page .wave {
    top: -100px;
  }
}

@media only screen and (max-width: 1024px)  {
  .our-services {
    margin-top: -50px;
  }
}

@media only screen and (max-width: 900px) {
  .talk.services-page {
    padding-top: 140px;

    .wave {
      top: -70px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .service-block {
    &__inner {
      flex-direction: column;
    }
    &__left {
      width: 100%;
      padding-right: 0;
      margin-bottom: 30px;
    }
    &__right {
      padding-left: 0;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 640px) {
  .talk.services-page .wave {
    top: -30px;
  }
}

@media only screen and (min-width: 600px) {
  .our-services__top {
    .swiper-wrapper {
      max-width: 1000px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 0;
      padding-bottom: 30px;
    }
  }
  
  .our-services__prev {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .our-services {
    position: relative;
    &__top {
      padding-left: 30px;
      padding-right: 30px;
    }
    .cont {
      width: 100%;
    }
    .service-block {
      padding-left: 30px;
      padding-right: 30px;
    }
    &__prev {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(90deg, rgba(5,36,83,1) 70%, rgba(5,36,83,0) 100%);
      width: 80px;
      height: 38px;
      left: 0;
      top: 0;
      z-index: 10;

      svg {
        width: 26px;
        height: 26px;
        margin-right: 25px;
      }
    }
    &__next {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(-90deg, rgba(5,36,83,1) 70%, rgba(5,36,83,0) 100%);
      width: 80px;
      height: 38px;
      right: 0;
      top: 0;
      z-index: 10;

      svg {
        width: 26px;
        height: 26px;
        margin-left: 25px;
        transform: rotate(180deg);
      }
    }
  }

  .solution-block {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 420px) {
  .our-services__prev {
    width: 50px;
    svg {
      margin-right: 5px;
    }
  }
  .our-services__next {
    width: 50px;
    svg {
      margin-left: 5px;
    }
  }
}